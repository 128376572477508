export const calculateDayTimeDifference = (eventDateInput: string) => {
    const eventDate = new Date(eventDateInput);
    const currentDate = new Date();

    const eventDateStartOfDay = new Date(eventDate.setHours(0, 0, 0, 0));
    const currentDateStartOfDay = new Date(currentDate.setHours(0, 0, 0, 0));

    const dayDifference = Math.floor(
        (currentDateStartOfDay.getTime() - eventDateStartOfDay.getTime()) / (1000 * 60 * 60 * 24)
    );

    return dayDifference < 0 ? dayDifference : Math.max(dayDifference, 0);
};

export const calculateSum = (expression: string): number => {
    return eval(expression);
};

export const calculateDateProgress = (startDate: Date, endDate: Date): number => {
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    const today = new Date().getTime();
  
    const totalDuration = end - start;
    const timePassed = end - today;
  
    const progress = (timePassed / totalDuration) * 100;
    return Math.max(0, Math.min(100, Math.round(progress)));
}