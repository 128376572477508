import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-order-info-timeline-item',
  templateUrl: './order-info-timeline-item.component.html',
  styleUrl: './order-info-timeline-item.component.scss',
})
export class OrderInfoTimelineItemComponent {
  @Input({required: true}) isLastItem : boolean = false;
  @Input({required: true}) title! : string;
  @Input({required: true}) date! : string;
  @Input({required: true}) detailedInfo! : string;
  @Input() alertType! : string;
}
