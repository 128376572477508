import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from '../../shared/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { AddUserComponent } from '../../shared/modals/add-user/add-user.component';
import { EditUserComponent } from '../../shared/modals/edit-user/edit-user.component';
import { ActivateUserConfirmComponent } from '../../shared/modals/activate-user-confirm/activate-user-confirm.component';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AuthGuard } from 'src/app/shared/guards/access-allowed.guard';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    public Auth: AuthGuard
  ) {}

  displayedColumns: string[] = [
    'firstName',
    'lastName',
    'email',
    'createdTimestamp',
    'enabled',
    'emailVerified',
    'notBefore',
  ];
  dataSource: any;
  showModalId: any;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngOnInit(): void {
    this.refreshTable();
  }

  addUser(): void {
    const dialogRef = this.dialog.open(AddUserComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.refreshTable();
    });
  }

  activateUser(row: any) {
    console.log(row);
    const id = row.value.id;
    const username = row.value.username;

    const dialogRef = this.dialog.open(ActivateUserConfirmComponent, {
      data: { username: username, isActivate: true },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.includes('confirm') && result.includes(true)) {
        this.userService.unblockUser(id, true).subscribe(res => {
          this.refreshTable();
        });
      } else if (result.includes('confirm') && result.includes(false)) {
        this.userService.unblockUser(id, false).subscribe(res => {
          this.refreshTable();
        });
      } else {
        this.refreshTable();
      }
    });
  }

  removeActions(): void {
    if (this.showModalId) {
      this.showModalId = false;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  deactivateUser(row: any) {
    console.log(row);
    const id = row.value.id;
    const username = row.value.username;
    const dialogRef = this.dialog.open(ActivateUserConfirmComponent, {
      data: { username: username, isActivate: false },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.includes('confirm')) {
        this.userService.blockUser(id).subscribe(res => {
          this.refreshTable();
        });
      }
    });
  }

  showModalActions(element: any) {
    setTimeout(() => {
      this.showModalId = element.id;
    }, 100);
  }

  show(row: any): void {
    console.log(row);
    const id = row.value.id;
    const dialogRef = this.dialog.open(EditUserComponent, {
      data: { isEdit: false, id: id },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.refreshTable();
    });
  }

  edit(id: any): void {
    const dialogRef = this.dialog.open(EditUserComponent, {
      data: { isEdit: true, id: id },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.refreshTable();
    });
  }

  refreshTable() {
    this.userService.getUsersAdmin().subscribe((res: any) => {
      this.dataSource = new MatTableDataSource(res['hydra:member']);
      this.dataSource.sort = this.sort;
      this.paginator._intl.itemsPerPageLabel = 'Elementy na stronę : ';
      this.dataSource.paginator = this.paginator;
    });
  }

  isEnabled(row: any) {
    console.log(row);
  }

  activateDeactivateUser(row: any) {
    if (row.value.enabled) {
      this.deactivateUser(row);
    } else {
      this.activateUser(row);
    }
  }
}
