import { Component, Input, OnInit } from '@angular/core';
import { calculateDateProgress, calculateSum } from '../../helpers/helpers';

export interface InsurancePeriodDetails {
  warrantyLength: number;
  pgInsuranceLength: number;
  nnwStartYearIndex: number;
  nnwInsuranceLength: number;
}

export type InsuranceType = '0+0/0+0' | '0+0' | '0/0+0' | '0+0/24m' | '0+0/12m';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss',
})


export class ProgressBarComponent implements OnInit {

    public isProgressBarExpanded: boolean = false;

    @Input({ required: true }) purchaseDate!: Date | null;
    @Input({ required: true }) insurancePeriod!: string;

    public insurancePeriodDetails!: InsurancePeriodDetails;
    public insuranceTypePattern!: InsuranceType;

    // NUMERIC:
    public maxWarrantyLengthinYears : number = 0;
    public orderPurchaseYear!: number;

    // PG:
    public pgStartDate! : Date;
    public pgEndDate! : Date;
    public pgInsuranceDateProgress!: number;

    // NNW:
    public nnwStartDate! : Date;
    public nnwEndDate! : Date;
    public nnwInsuranceDateProgress!: number;

    constructor() { }

    ngOnInit(): void {
      this.calculateInsurancePeriodDetails();
      this.calculateMaxWarrantyLength();
      this.calculatePGWarrantyPeriod();
      this.calculateNNWWarrantyPeriod();
      this.calculateInsuranceDateProgress();
      this.getOrderPurchaseYear();
    }

    calculateInsurancePeriodDetails() : void {
      const PGandNNWregex = /^\d+\+\d+\/\d+\+\d+$/; // 2
      const PGregex = /^\d+\+\d+$/; // 1
      const NNWregex = /^\d+\/\d+\+\d+$/; // 1
      const PU24M = /^\d+\+\d+\/24m$/; // 2
      const PU12M = /^\d+\+\d+\/12m$/; // 2
      const insurancePeriod = this.insurancePeriod.replace(",","/");
      if (PGandNNWregex.test(insurancePeriod)) {
        this.insuranceTypePattern = '0+0/0+0';
        const warrantyPeriods = insurancePeriod.split("/");
        this.insurancePeriodDetails = {
          warrantyLength: + warrantyPeriods[0][0],
          pgInsuranceLength: + warrantyPeriods[0][2],
          nnwStartYearIndex: + warrantyPeriods[1][0],
          nnwInsuranceLength: + warrantyPeriods[1][2]
        }
      }
      else if(PGregex.test(insurancePeriod)) {
        this.insuranceTypePattern = '0+0';
        this.insurancePeriodDetails = {
          warrantyLength: + insurancePeriod[0],
          pgInsuranceLength: + insurancePeriod[2],
          nnwStartYearIndex: 0,
          nnwInsuranceLength: 0
        }
      }
      else if(NNWregex.test(insurancePeriod)) {
        this.insuranceTypePattern = '0/0+0';
        const warrantyPeriods = insurancePeriod.split("/");
        this.insurancePeriodDetails = {
          warrantyLength: + warrantyPeriods[0][0],
          pgInsuranceLength: 0,
          nnwStartYearIndex: + warrantyPeriods[1][0],
          nnwInsuranceLength: + warrantyPeriods[1][2]
        }
      }
      else if(PU24M.test(insurancePeriod)) {
        this.insurancePeriod = insurancePeriod.replace("24m","0+2");
        this.calculateInsurancePeriodDetails();
      }
      else if(PU12M.test(insurancePeriod)) {
        this.insurancePeriod = insurancePeriod.replace("12m","0+1");
        this.calculateInsurancePeriodDetails();
      }
    }

    calculateMaxWarrantyLength() : void {
      const insurancePeriod = this.insurancePeriod.replace(",","/");
      const warrantyPeriods = insurancePeriod.split("/");
      const sums = warrantyPeriods.map(calculateSum);
      const maxSumIndex = sums.indexOf(Math.max(...sums));
      this.maxWarrantyLengthinYears = sums[maxSumIndex];
    }

    calculatePGWarrantyPeriod() : void {
      if (this.purchaseDate) {
        const purchaseDate = new Date(this.purchaseDate);
        purchaseDate.setFullYear(purchaseDate.getFullYear() + this.insurancePeriodDetails.warrantyLength);
        this.pgStartDate = new Date(purchaseDate);
        purchaseDate.setFullYear(purchaseDate.getFullYear() + this.insurancePeriodDetails.pgInsuranceLength);
        this.pgEndDate = purchaseDate;
      }
    }

    calculateNNWWarrantyPeriod() : void {
      if (this.purchaseDate) {
        const purchaseDate = new Date(this.purchaseDate);
        purchaseDate.setFullYear(purchaseDate.getFullYear() + this.insurancePeriodDetails.nnwStartYearIndex);
        this.nnwStartDate = new Date(purchaseDate);
        purchaseDate.setFullYear(purchaseDate.getFullYear() + this.insurancePeriodDetails.nnwInsuranceLength);
        this.nnwEndDate = purchaseDate;
      }
    }

    calculateInsuranceDateProgress() : void {
      this.pgInsuranceDateProgress = calculateDateProgress(this.pgStartDate, this.pgEndDate);
      this.nnwInsuranceDateProgress = calculateDateProgress(this.nnwStartDate, this.nnwEndDate);
    }

    getOrderPurchaseYear() : void {
      if (this.purchaseDate) {
        const purchaseDate = new Date(this.purchaseDate);
        this.orderPurchaseYear = purchaseDate.getFullYear();
      }
    }

    toggleProgressBarExpand() : void {
      console.log(this.isProgressBarExpanded);
      
      this.isProgressBarExpanded = !this.isProgressBarExpanded;
    }
}