<div class="sidenav">
  <div class="sidenav-logo-container">
    <img class="sidenav-logo" [src]="appLogo"/>
  </div>
<!-- routerLinkActive="active" -->
  <div class="sidenav-routes-container">
    @for (appRoute of appRoutes; track $index) {
      @if(appRoute.type === 'link' && appRoute.canActivate) {
        <div class="item" [routerLink]="appRoute.route" [class.sectionEnd]="appRoute.sectionEnd" routerLinkActive="active">
          <div class="expand-item-content-container">
            <div class="icon" [matTooltip]="appRoute.title | translate">
              <mat-icon>{{appRoute.icon}}</mat-icon>
            </div>
            <div class="text">{{ appRoute.title | translate }}</div>
          </div>
        </div>
      }
  
      @if(appRoute.type === 'expand' && appRoute.canActivate) {
      <div class="item" (click)="toggleProperty(appRoute.expandProperty || '')">
        <div class="expand-item-content-container">
          <div class="icon" [matTooltip]="appRoute.title | translate">
            <mat-icon>{{ appRoute.icon }}</mat-icon>
          </div>
          <div class="text">{{ appRoute.title | translate}}</div>
        </div>
        <div class="arrow">
          <mat-icon>{{this[appRoute.expandProperty || ''] ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
        </div>
      </div>
        @if(this[appRoute.expandProperty || '']) {
          @for (childRoute of appRoute.childRoutes; track $index) {
            @if(childRoute.canActivate){ 
              <div class="item child-route" [routerLink]="childRoute.route" routerLinkActive="active">
                <div class="expand-item-content-container">
                  <mat-icon>{{childRoute.icon}}</mat-icon>
                  <div class="text">{{ childRoute.title | translate}}</div>
                </div>
              </div>
            }
          }
        }
      }
    }
  </div>

  <div class="user-details-footer">
    <div
      class="user-info"
      [matMenuTriggerFor]="menu"
      (mouseover)="createMenuTrigger.openMenu()"
      #createMenuTrigger="matMenuTrigger">
      <div class="icon">
        <mat-icon>person</mat-icon>
      </div>
      <div class="name">
        <p>{{ user?.name }} {{ user?.surname }}</p>
      </div>
    </div>
    <mat-menu
      class="user-menu"
      #menu="matMenu"
      yPosition="above"
      (mouseleave)="createMenuTrigger.closeMenu()">
      <div class="menu-item" [routerLink]="'/my-profile'">
        {{ 'users-front.my_account' | translate }}
      </div>
      <hr class="custom-hr menu-item" />
      <div (click)="logout()" class="menu-item">
        {{ 'users-front.logout' | translate }}
      </div>
    </mat-menu>
  </div>
</div>




      <!-- <div class="item" routerLink="/orders" routerLinkActive="active">
      <div class="icon" matTooltip="{{ 'navbar.orders' | translate }}">
        <mat-icon>dashboard</mat-icon>
      </div>
        <div class="text">{{ 'navbar.orders' | translate }}</div>
    </div> -->

    <!-- @if(Auth.isViewAllowed(['ROLE_BILLINGS_COSTS_VIEW', 'ROLE_BILLINGS_REVENUE_VIEW', 'ROLE_ADMIN'])){
    <div class="item" (click)="toggleCostsVisibility()">
      <div class="icon" matTooltip="{{ 'navbar.settlements' | translate }}">
        <mat-icon>assignment</mat-icon>
      </div>
      <div class="text" *ngIf="opened">
        {{ 'navbar.settlements' | translate }}
      </div>
      <div class="arrow" *ngIf="!isCostTabExpanded">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </div>
      <div class="arrow" *ngIf="isCostTabExpanded">
        <mat-icon>keyboard_arrow_up</mat-icon>
      </div>
    </div>
    } -->

    <!-- <div
      class="item-items"
      *ngIf="isCostTabExpanded"
      [ngClass]="{ 'sidenav-items-closed': opened == false }">
      <div
        class="item"
        routerLink="/costs"
        routerLinkActive="active"
        *ngIf="Auth.isViewAllowed(['ROLE_BILLINGS_COSTS_VIEW', 'ROLE_ADMIN'])">
        <div class="icon" matTooltip="{{ 'navbar.costs' | translate }}">
          <mat-icon class="small-icon" *ngIf="opened"
            >fiber_manual_record</mat-icon
          >
          <mat-icon *ngIf="!opened">payment</mat-icon>
        </div>
        <div class="text" *ngIf="opened">{{ 'navbar.costs' | translate }}</div>
      </div>
      <div
        class="item"
        routerLink="/settlements"
        routerLinkActive="active"
        *ngIf="
          Auth.isViewAllowed(['ROLE_BILLINGS_REVENUE_VIEW', 'ROLE_ADMIN'])
        ">
        <div class="icon" matTooltip="{{ 'navbar.revenue' | translate }}">
          <mat-icon class="small-icon" *ngIf="opened"
            >fiber_manual_record</mat-icon
          >
          <mat-icon *ngIf="!opened">receipt</mat-icon>
        </div>
        <div class="text" *ngIf="opened">
          {{ 'navbar.revenue' | translate }}
        </div>
      </div>
    </div> -->
    <!-- <div
      class="item section-end"
      routerLink="/workshop"
      routerLinkActive="active"
      *ngIf="Auth.isViewAllowed(['ROLE_WORKSHOP_VIEW', 'ROLE_ADMIN'])">
      <div class="icon" matTooltip="{{ 'navbar.services' | translate }}">
        <mat-icon>build</mat-icon>
      </div>
      <div class="text" *ngIf="opened">{{ 'navbar.services' | translate }}</div>
    </div>

    <div class="item" routerLink="/archived-orders" routerLinkActive="active">
      <div class="icon" matTooltip="{{ 'navbar.archived-orders' | translate }}">
        <mat-icon>list_alt</mat-icon>
      </div>
      <div class="text" *ngIf="opened">
        {{ 'navbar.archived-orders' | translate }}
      </div>
    </div> -->

    <!-- <div
      class="item section-end"
      routerLink="/reports"
      routerLinkActive="active"
      *ngIf="Auth.isViewAllowed(['ROLE_REPORTS', 'ROLE_ADMIN'])">
      <div class="icon" matTooltip="{{ 'navbar.reports' | translate }}">
        <mat-icon>bar_chart</mat-icon>
      </div>
      <div class="text" *ngIf="opened">
        {{ 'navbar.reports' | translate }}
      </div>
    </div> -->

    <!-- <div
      class="item"
      (click)="toggleSettingsVisibility()"
      *ngIf="
        Auth.isViewAllowed([
          'ROLE_LOGS',
          'ROLE_CRONS',
          'ROLE_SYSTEM_MARGIN_SETTINGS',
          'ROLE_SETTINGS_USERS_VIEW',
          'ROLE_ADMIN',
        ])
      ">
      <div class="icon" matTooltip="{{ 'navbar.settings' | translate }}">
        <mat-icon>settings</mat-icon>
      </div>
      <div class="text" *ngIf="opened">
        {{ 'navbar.settings' | translate }}
      </div>
      <div class="arrow" *ngIf="!isSettingsTabExpanded">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </div>
      <div class="arrow" *ngIf="isSettingsTabExpanded">
        <mat-icon>keyboard_arrow_up</mat-icon>
      </div>
    </div>
    <div
      class="item-items"
      *ngIf="isSettingsTabExpanded"
      [ngClass]="{ 'sidenav-items-closed': opened == false }">
      <div
        *ngIf="Auth.isViewAllowed(['ROLE_SETTINGS_USERS_VIEW', 'ROLE_ADMIN'])"
        class="item"
        routerLink="/users"
        routerLinkActive="active">
        <div class="icon" matTooltip="{{ 'navbar.users' | translate }}">
          <mat-icon class="small-icon" *ngIf="opened"
            >fiber_manual_record</mat-icon
          >
          <mat-icon *ngIf="!opened">assignment_ind</mat-icon>
        </div>
        <div class="text" *ngIf="opened">{{ 'navbar.users' | translate }}</div>
      </div>
      <div
        class="item"
        routerLink="/settings"
        routerLinkActive="active"
        *ngIf="
          Auth.isViewAllowed(['ROLE_SYSTEM_MARGIN_SETTINGS', 'ROLE_ADMIN'])
        ">
        <div class="icon" matTooltip="{{ 'navbar.margin_system' | translate }}">
          <mat-icon class="small-icon" *ngIf="opened"
            >fiber_manual_record</mat-icon
          >
          <mat-icon *ngIf="!opened">extension icon</mat-icon>
        </div>
        <div class="text" *ngIf="opened">
          {{ 'navbar.margin_system' | translate }}
        </div>
      </div>
      <div
        *ngIf="Auth.isViewAllowed(['ROLE_CRONS', 'ROLE_ADMIN'])"
        class="item"
        routerLink="/admin-panel"
        routerLinkActive="active">
        <div class="icon" matTooltip="{{ 'navbar.crons' | translate }}">
          <mat-icon class="small-icon" *ngIf="opened"
            >fiber_manual_record</mat-icon
          >
          <mat-icon *ngIf="!opened">brightness_auto</mat-icon>
        </div>
        <div class="text" *ngIf="opened">
          {{ 'navbar.crons' | translate }}
        </div>
      </div>

      <div
        *ngIf="Auth.isViewAllowed(['ROLE_LOGS', 'ROLE_ADMIN'])"
        class="item"
        routerLink="/logs"
        routerLinkActive="active">
        <div class="icon" matTooltip="{{ 'navbar.logs' | translate }}">
          <mat-icon class="small-icon" *ngIf="opened"
            >fiber_manual_record</mat-icon
          >
          <mat-icon *ngIf="!opened">memory</mat-icon>
        </div>
        <div class="text" *ngIf="opened">
          {{ 'navbar.logs' | translate }}
        </div>
      </div>
      <div
        *ngIf="Auth.isViewAllowed(['ROLE_LOGS_LOCAL', 'ROLE_ADMIN'])"
        class="item"
        routerLink="/logs-local"
        routerLinkActive="active">
        <div class="icon" matTooltip="{{ 'navbar.logs-local' | translate }}">
          <mat-icon class="small-icon" *ngIf="opened"
            >fiber_manual_record</mat-icon
          >
          <mat-icon *ngIf="!opened">memory</mat-icon>
        </div>
        <div class="text" *ngIf="opened">
          {{ 'navbar.logs-local' | translate }}
        </div>
      </div>
    </div>
  <div> -->