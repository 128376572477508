import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services/services.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SupportedHardwareComponent } from '../../../shared/modals/supported-hardware/supported-hardware.component';
import { ServiceMarginComponent } from '../../../shared/modals/service-margin/service-margin.component';
import { SupportedAreaComponent } from '../../../shared/modals/supported-area/supported-area.component';
import { AddContactComponent } from '../../../shared/modals/add-contact/add-contact.component';
import { DeviceService } from '../../../shared/services/device.service';
import { timeout } from 'rxjs';
import { AuthGuard } from 'src/app/shared/guards/access-allowed.guard';
import { EditServiceInfoComponent } from 'src/app/shared/modals/edit-service-info/edit-service-info.component';
import { EditContactComponent } from 'src/app/shared/modals/edit-contact/edit-contact';
import { SupportedBrandsComponent } from 'src/app/shared/modals/supported-brands/supported-brands.component';
import { DeleteServiceConfirmComponent } from 'src/app/shared/modals/delete-service-confirm/delete-service-confirm.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';

@Component({
  selector: 'app-service-item',
  templateUrl: './service-item.component.html',
  styleUrls: ['./service-item.component.scss'],
})
export class ServiceItemComponent implements OnInit {
  public service: any;
  public contacts: any = [];
  public devices: any = [];
  public selectedDetails: any;
  public showActionsId: any;
  public supportedBrands: any = [];
  workshopId: any;

  constructor(
    public route: ActivatedRoute,
    private dialog: MatDialog,
    private deviceService: DeviceService,
    private servicesService: ServicesService,
    public router: Router,
    public Auth: AuthGuard,
    public _snackBar: MatSnackBar,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  editMargin(): void {
    const dialogRef = this.dialog.open(ServiceMarginComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'show') {
      }
    });
  }

  editSupported(): void {
    const dialogRef = this.dialog.open(SupportedHardwareComponent, {
      data: { service: this.service },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      timeout(1000);
      this.getData();
    });
  }

  editArea(): void {
    const dialogRef = this.dialog.open(SupportedAreaComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'show') {
      }
    });
  }

  addContact(): void {
    const dialogRef = this.dialog.open(AddContactComponent, {
      data: { service: this.service },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getData();
    });
  }

  showContactDetails(index: number): void {
    if (this.selectedDetails == index) {
      this.selectedDetails = null;
    } else {
      this.selectedDetails = index;
    }
  }

  showActions(id: string): void {
    setTimeout(() => {
      this.showActionsId = id;
    }, 100);
  }

  removeActions(): void {
    this.showActionsId = null;
  }

  getData(): void {
    this.workshopId = this.route.snapshot.params['id'];
    this.servicesService
      .getServiceById(this.route.snapshot.params['id'])
      .subscribe(res => {
        this.service = res;
        this.breadcrumbService.setBreadCrumbDetails(this.service?.name);
        this.contacts = [];

        this.service.contacts.forEach(
          (element: any, filteredDataIndex: string | number) => {
            this.servicesService
              .getServiceContactByUrl(element)
              .subscribe((res: any) => {
                this.contacts[filteredDataIndex] = res;
              });
          }
        );

        this.service.workshopDeviceTypes.forEach(
          (element: any, filteredDataIndex: string | number) => {
            this.deviceService
              .getServiceDeviceTypesByWorkshop(element)
              .subscribe((res: any) => {
                this.devices[filteredDataIndex] = res;
              });
          }
        );
        this.getSupportedBrands();
      });
  }

  deleteContact(contract: any): void {
    this.servicesService.deleteServiceContact(contract).subscribe(res => {
      this.getData();
    });
  }

  editContact(contact: any): void {
    const dialogRef = this.dialog.open(EditContactComponent, {
      data: { contact },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      this.contacts = Array(result);
      this.getData();
    });
  }

  editService(): void {
    const dialogRef = this.dialog.open(EditServiceInfoComponent, {
      data: { id: this.route.snapshot.params['id'] },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getData();
    });
  }

  getSupportedBrands(): void {
    this.servicesService.getSupportedBrandsComponent().subscribe((res: any) => {
      this.supportedBrands = res['hydra:member'];

      this.supportedBrands.forEach((supportedBrand: any) => {
        this.service.brand.forEach((serviceBrand: any, index: number) => {
          if (supportedBrand['@id'] === serviceBrand) {
            this.service.brand[index] = supportedBrand;
          }
        });
      });
    });
  }

  editBrands(): void {
    const dialogRef = this.dialog.open(SupportedBrandsComponent, {
      data: { service: this.service },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getData();
      this.getSupportedBrands();
    });
  }

  removeUser(id: any) {
    const dialogRef = this.dialog.open(DeleteServiceConfirmComponent, {
      data: { id: id },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'confirm') {
        this.servicesService.deleteService(id).subscribe(
          response => {
            this._snackBar.open('Usunięto', 'Zamknij', {
              duration: 1200,
            });
            this.router.navigate(['/workshop']);
          },
          error => {
            this._snackBar.open(error, 'Zamknij', {
              duration: 12000,
            });
          }
        );
      }
    });
  }

  deactivate() {
    const body = {
      active: false,
    };
    this.servicesService
      .patchServices(this.route.snapshot.params['id'], body)
      .subscribe(
        response => {
          this.getData();
          this._snackBar.open('Dezaktywowano seriws', 'Zamknij', {
            duration: 1200,
          });
        },
        error => {
          this._snackBar.open(error, 'Zamknij', {
            duration: 12000,
          });
        }
      );
  }
  activate() {
    const body = {
      active: true,
    };
    this.servicesService
      .patchServices(this.route.snapshot.params['id'], body)
      .subscribe(
        response => {
          this.getData();
          this._snackBar.open('Aktywowano seriws', 'Zamknij', {
            duration: 1200,
          });
        },
        error => {
          this._snackBar.open(error, 'Zamknij', {
            duration: 12000,
          });
        }
      );
  }
}
