<ng-container>
  <mat-card>
    <ng-container *ngIf="dataSourceErrors.data.length === 0">
      <div class="empty-table-view">
        <p>No data available.</p>
      </div>
    </ng-container>

    <table
      mat-table
      [dataSource]="dataSourceErrors"
      class="mat-elevation-z8"
      *ngIf="dataSourceErrors.data.length > 0">
      <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ 'name' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'message' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.message }}</td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="ok">
        <th mat-header-cell *matHeaderCellDef>{{ 'ok' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.ok }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'status' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.status }}
        </td>
      </ng-container>

      <ng-container matColumnDef="url">
        <th mat-header-cell *matHeaderCellDef>{{ 'url' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.url }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsErrors"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsErrors"></tr>
    </table>
    <div class="empty-table-view" *ngIf="dataSourceErrors.data.length === 0">
      <p>{{ 'no_data' | translate }}</p>
    </div>
  </mat-card>
</ng-container>
