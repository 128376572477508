import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NotificationPreAuthHydraMember } from '../../../../../dashboard-libs/models/notificationPreAuth.model';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-preAuth',
  templateUrl: './preAuth.html',
  styleUrls: ['./preAuth.scss'],
})
export class PreAuthComponent implements OnInit{
  public preAuth: NotificationPreAuthHydraMember[] = [];
  public isLoading = true;

  constructor(private notificationService: NotificationService) {}

  @Output() dismissOpenModal = new EventEmitter<void>();

  onDismissOpenModal() : void {
    this.dismissOpenModal.emit();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.notificationService.preAuthorizationNotifications$.subscribe({
      next: (preAuthData) => {
        this.preAuth = preAuthData;
        this.isLoading = false;
      },
      error: (err) => {
        console.error('Error loading pre-authorization notifications:', err);
        this.isLoading = false;
      },
    });
  }
}
