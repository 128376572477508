<ng-container>
  <div class="tabs">
    <div
      class="tab"
      (click)="changeGroup(item)"
      [class.active]="tab == item.name"
      *ngFor="let item of statusGroup">
      <p>
        <b>{{ item.name }}</b>
      </p>

      <p *ngIf="item.name != 'Do akceptacji'" class="center blue">
        {{ statesCountGrouped[item.name] || 0 }}
      </p>
      <p class="center blue" *ngIf="item.name == 'Do akceptacji'">
        <b>{{ preAuthCount || 0 }}</b>
      </p>
    </div>
  </div>

  <mat-card>
    <div
      class="filters"
      *ngIf="Auth.isViewAllowed(['ROLE_ORDERS_SEARCH', 'ROLE_ADMIN'])">
      <div
        class="buttons right"
        *ngIf="Auth.isViewAllowed(['ROLE_ORDERS_SEARCH', 'ROLE_ADMIN'])">
        <button
          class="filter-button"
          mat-raised-button
          color="primary"
          (click)="sendFilters()">
          {{ 'search' | translate }}
        </button>
        <button
          class="filter-button"
          mat-stroked-button
          (click)="clearFilters()"
          color="primary">
          {{ 'clear' | translate }}
        </button>

        <button
          class="filter-button"
          mat-stroked-button
          (click)="showFilters = !showFilters"
          color="primary">
          <p *ngIf="!showFilters">
            <mat-icon>filter_alt</mat-icon>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </p>
          <p *ngIf="showFilters">
            <mat-icon>filter_alt</mat-icon>
            <mat-icon>keyboard_arrow_up</mat-icon>
          </p>
        </button>
      </div>
      <div class="filters-inputs" [ngClass]="{ 'showFilters': showFilters }">
        <mat-form-field class="filter-field" appearance="outline">
          <mat-label class="form-label">{{
            'faultNumber' | translate
          }}</mat-label>
          <input
            matInput
            class="form-field"
            [(ngModel)]="faultNumberValue"
            [formControl]="faultNumber"
            (keydown.enter)="sendFilters()" />
          @if (faultNumberValue) {
            <button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="this.faultNumber.setValue(''); sendFilters()">
              <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>

        <mat-form-field class="filter-field" appearance="outline">
          <mat-label class="form-label">{{
            'createdAtFrom' | translate
          }}</mat-label>
          <input
            matInput
            class="form-label"
            [(ngModel)]="createdAtFromValue"
            [matDatepicker]="picker1"
            [formControl]="createdAtFrom" />

          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          @if (createdAtFromValue) {
            <button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="this.createdAtFrom.setValue(''); sendFilters()">
              <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>
        <mat-form-field class="filter-field" appearance="outline">
          <mat-label class="form-label">{{
            'createdAtTo' | translate
          }}</mat-label>
          <input
            matInput
            class="form-label"
            [matDatepicker]="picker2"
            [(ngModel)]="createdAtToValue"
            [formControl]="createdAtTo" />

          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          @if (createdAtToValue) {
            <button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="this.createdAtTo.setValue(''); sendFilters()">
              <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>

        <!-- <mat-form-field class="filter-field" appearance="outline">
          <mat-label class="form-label">{{ 'state' | translate }}</mat-label>

          <mat-select [formControl]="state" multiple>
            @for (topping of stateList; track topping) {
            <mat-option [value]="topping">{{ topping | translate }}</mat-option>
            }
          </mat-select>
        </mat-form-field> -->
        <mat-form-field class="filter-field" appearance="outline">
          <mat-label class="form-label">{{ 'preauth' | translate }}</mat-label>

          <mat-select
            [formControl]="preauth"
            multiple
            [(ngModel)]="preauthValue">
            @for (topping of preauthList; track topping) {
              <mat-option [value]="topping">{{
                'preauthorizationStatus.' + topping | translate
              }}</mat-option>
            }
          </mat-select>
          @if (preauthValue) {
            <button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="this.preauth.setValue(''); sendFilters()">
              <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>
        <mat-form-field class="filter-field" appearance="outline">
          <mat-label class="form-label">{{ 'workshop' | translate }}</mat-label>
          <mat-select
            [(ngModel)]="workshopNameValue"
            [formControl]="workshopName"
            (keydown.enter)="sendFilters()">
            <mat-option
              *ngFor="let workshop of workshops"
              [value]="workshop.name">
              {{ workshop.name }}</mat-option
            >
          </mat-select>

          @if (workshopNameValue) {
            <button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="this.workshopName.setValue(''); sendFilters()">
              <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>
        <mat-form-field class="filter-field" appearance="outline">
          <mat-label class="form-label">{{
            'Udział własny' | translate
          }}</mat-label>
          <mat-select
            [(ngModel)]="deductibleCostsExistsValue"
            [formControl]="deductibleCostsExists"
            (keydown.enter)="sendFilters()">
            <mat-option [value]="null"> </mat-option>
            <mat-option [value]="'yes'"> Z udziałem własnym</mat-option>
            <mat-option [value]="'no'"> Bez udziału własnego</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="filter-field" appearance="outline">
          <mat-label class="form-label">{{ 'device' | translate }}</mat-label>
          <input
            matInput
            [(ngModel)]="deviceNameValue"
            class="form-field"
            [formControl]="deviceName"
            (keydown.enter)="sendFilters()" />
          @if (deviceNameValue) {
            <button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="this.deviceName.setValue(''); sendFilters()">
              <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>
        <mat-form-field class="filter-field" appearance="outline">
          <mat-label class="form-label">{{
            'repairCode' | translate
          }}</mat-label>
          <input
            [(ngModel)]="repairCodeValue"
            matInput
            class="form-field"
            [formControl]="repairCode"
            (keydown.enter)="sendFilters()" />
          @if (repairCodeValue) {
            <button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="this.repairCode.setValue(''); sendFilters()">
              <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>
      </div>
    </div>
    <div class="table">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        (matSortChange)="sortData($event)">
        <ng-container matColumnDef="Lp">
          <th mat-header-cell *matHeaderCellDef>Lp.</th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ itemsPerPage * pageIndex + i + 1 }}
          </td>
        </ng-container>
        <ng-container matColumnDef="faultNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'orders-front.table.order_number' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{
              'warning-alert':
                element?.alerts?.faultNumber?.alertType == 'warning',
              'danger-alert':
                element?.alerts?.faultNumber?.alertType == 'danger',
              'info-alert': element?.alerts?.faultNumber?.alertType == 'info',
            }"
            matTooltip="{{
              element?.alerts?.faultNumber?.message | translate
            }}">
            <p
              [ngClass]="element?.alertType"
              [matTooltip]="
                element?.alertType == 'warning'
                  ? 'Problem ze zeleceniem'
                  : '' || element?.alertType == 'success'
                    ? 'Zlecenie udało się '
                    : '' || element?.alertType == 'danger'
                      ? 'Zlecenie ma problem '
                      : ''
              ">
              {{ element.faultNumber | dashIfEmpty }}
            </p>
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'orders-front.table.order_date' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <p [ngClass]="element?.alertType">
              {{
                element.createdAt | date: 'YYYY-MM-dd HH:mm:ss' | dashIfEmpty
              }}
            </p>
            <p></p>
          </td>
        </ng-container>

        <ng-container matColumnDef="repairCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'orders-front.table.state_in_service' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.repairCode | dashIfEmpty }}
          </td>
        </ng-container>

        <ng-container matColumnDef="preauthorizationStatus">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'orders-front.table.preauthorizationStatus' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{
              'warning-alert': element?.alerts?.state?.alertType == 'warning',
              'danger-alert': element?.alerts?.state?.alertType == 'danger',
              'info-alert': element?.alerts?.state?.alertType == 'info',
            }"
            matTooltip="{{ element?.alerts?.state?.message | translate }}">
            {{
              'preauthorizationStatus.' + element.preauthorizationStatus
                | translate
                | dashIfEmpty
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="service">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'orders-front.table.service' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.workshopName | dashIfEmpty }}
          </td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'orders-front.table.state' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.state | dashIfEmpty | translate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="deviceName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'orders-front.table.device' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="ellipsis"
            isEllipsisActive>
            {{ element.deviceName | dashIfEmpty }}
          </td>
        </ng-container>

        <ng-container matColumnDef="vendorCity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'orders-front.table.city' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.vendorCity | dashIfEmpty }}
          </td>
        </ng-container>

        <ng-container matColumnDef="serviceTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'orders-front.table.service_time' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{
              'warning-alert':
                element?.alerts?.orderDays?.alertType == 'warning',
              'danger-alert': element?.alerts?.orderDays?.alertType == 'danger',
            }"
            matTooltip="{{ element?.alerts?.orderDays?.message | translate }}">
            {{ element.orderDays.days | dashIfEmpty }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'orders-front.table.actions' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon>more_vert</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          class="hovered"
          mat-row
          (click)="goToDetails(row.id, $event)"
          [contextMenu]="oneContextMenu"
          [contextMenuValue]="row"
          *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <div class="emptyTable" *ngIf="dataSource?.length === 0">Brak danych</div>
      <mat-paginator
        [pageSizeOptions]="[10, 20, 30, 50]"
        [pageSize]="itemsPerPage"
        [pageIndex]="pageIndex"
        (page)="onPaginateChange($event)"
        aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>
  </mat-card>
</ng-container>

<context-menu #oneContextMenu menuClass="custom-style">
  <ng-template contextMenuItem let-value (execute)="openInNewTabOrder($event)">
    Otwórz w nowym oknie</ng-template
  >
  <ng-template contextMenuItem [subMenu]="specialPast"
    >Kopiuj do schowka ...</ng-template
  >
  <ng-template
    contextMenuItem
    (execute)="openInNewTabSettlement($event)"
    let-value>
    Przejdz do rozliczenia tego zlecenia</ng-template
  >
  <ng-template contextMenuItem (execute)="openInNewTabCost($event)" let-value>
    Przejdz do kosztów tego zlecenia</ng-template
  >
  <ng-template contextMenuItem (execute)="openLogsOfOrder($event)" let-value
    >Przejdz do logów zlecenia</ng-template
  >
</context-menu>

<context-menu #specialPast>
  <ng-template contextMenuItem (execute)="copy($event, 'faultNumber')">
    Nr zlecenia</ng-template
  >
  <ng-template contextMenuItem (execute)="copy($event, 'deviceName')"
    >Nazwę sprzętu
  </ng-template>
  <ng-template contextMenuItem (execute)="copy($event, 'workshopName')"
    >Serwis</ng-template
  >
</context-menu>
