<div class="progress-bar-wrapper" [class.expanded]="isProgressBarExpanded" (click)="toggleProgressBarExpand()">
    <div class="progress-bar-date-container pg-date-container">
        @if(["0+0/0+0","0+0"].includes(insuranceTypePattern)){
            <p>{{pgStartDate | date: 'YYYY-MM-dd'}}</p>
        }
        @if(["0+0/0+0","0/0+0"].includes(insuranceTypePattern)) {
            <p>{{nnwStartDate| date: 'YYYY-MM-dd'}}</p>
        }
        <p class="progress-bar-date-legend"></p>
    </div>
    <div class="progress-bar-container">
        @if(["0+0/0+0","0+0"].includes(insuranceTypePattern)){
            <div class="progress-bar-pg-container" [style.gridTemplateColumns]="'repeat('+maxWarrantyLengthinYears+', 1fr)'">
                <div class="progress-bar pg-progress-bar" [style.gridColumn]="(insurancePeriodDetails.warrantyLength + 1) +' / span '+ insurancePeriodDetails.pgInsuranceLength">
                    <p class="progress-bar-title">PG</p>
                    <div class="progress-bar-inner" [style.width]="pgInsuranceDateProgress + '%'"></div>
                </div>
            </div>   
        }
        @if(["0+0/0+0","0/0+0"].includes(insuranceTypePattern)) {
            <div class="progress-bar-nnw-container" [style.gridTemplateColumns]="'repeat('+maxWarrantyLengthinYears+',1fr)'">
                <div class="progress-bar nnw-progress-bar" [style.gridColumn]="(insurancePeriodDetails.nnwStartYearIndex + 1) +' / span '+ insurancePeriodDetails.nnwInsuranceLength">
                    <p class="progress-bar-title">NNW</p>
                    <div class="progress-bar-inner" [style.width]="nnwInsuranceDateProgress + '%'"></div>
                </div>
            </div>
        }

        <div class="progress-bar-legend" [style.gridTemplateColumns]="'repeat('+maxWarrantyLengthinYears+',1fr)'">
            @for (border of [].constructor(maxWarrantyLengthinYears); track $index; let i = $index) {
                <div class="progress-bar-legend-border">
                    <p>{{purchaseDate | date: 'dd.MM.'}}{{orderPurchaseYear + i}}</p>
                </div>
            }
        </div>
    </div>
    <div class="progress-bar-date-container nnw-date-container">
        @if(["0+0/0+0","0+0"].includes(insuranceTypePattern)){
            <p>{{pgEndDate | date: 'YYYY-MM-dd'}}</p>
        }
        @if(["0+0/0+0","0/0+0"].includes(insuranceTypePattern)) {
            <p>{{nnwEndDate | date: 'YYYY-MM-dd'}}</p>
        }
        <p class="progress-bar-date-legend"></p>
    </div>
</div>