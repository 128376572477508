<div class="notification-item">
    @switch (notificationType) {
        @case ("drs") {
            <span class="notification-icon" [style.background]="'#4B5675'">
                <fa-icon [icon]="faMessage"/>
            </span>
        }
        @case ("provider") {
            <span class="notification-icon" [style.background]="'#2972B7'">
                <fa-icon [icon]="faUser"/>
            </span>
        }
        @case ("partner") {
            <span class="notification-icon" [style.background]="'#F26B2B'">
                <fa-icon [icon]="faWrench"/>
            </span>
        }
        @default {}
    }
    <div class="notification-item-content">
        <h6>{{title}}</h6>
        @if(description){
            <p>{{description}}</p>
        }
    </div>
    <div class="notification-item-time">
        <span>{{time | timeAgo | translate}}</span>
    </div>
</div>