import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NotificationOrderHydraMember } from '../../../../../dashboard-libs/models/notificationOrder.model';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.html',
  styleUrls: ['./notifications.scss'],
})
export class OrderNotificationsComponent implements OnInit{
  public isLoading = true;
  public orders: NotificationOrderHydraMember[] = [];

  constructor(private notificationService: NotificationService) {}

  @Output() dismissOpenModal = new EventEmitter<void>();

  onDismissOpenModal() : void {
    this.dismissOpenModal.emit();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.notificationService.orderNotifications$.subscribe({
      next: (orders) => {
        this.orders = orders;
        this.isLoading = false;
      },
      error: (err) => {
        console.error('Error loading order notifications:', err);
        this.isLoading = false;
      },
    });
  }
}
