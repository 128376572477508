import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthGuard } from 'dashboard-libs/guards/access-allowed.guard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrdersService } from 'src/app/pages/orders/services/orders.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-preauth-modal-modal',
  templateUrl: './add-preauth-modal.component.html',
  styleUrls: ['./add-preauth-modal.component.scss'],
})
export class AddPreauthModalComponent implements OnInit {
  error: any = null;
  preauthForm!: FormGroup;
  submitted: any;

  constructor(
    private dialogRef: MatDialogRef<AddPreauthModalComponent>,
    public Auth: AuthGuard,
    private fb: FormBuilder,
    public ordersService: OrdersService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.preauthForm = this.fb.group({
      code: ['', Validators.required],
      action: ['', Validators.required],
      diagnosis: ['', Validators.required],
      actionCode: ['', Validators.required],
      estimatedCost: [
        '',
        [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)],
      ],
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    console.log(this.data);
    this.submitted = true;
    if (this.preauthForm.valid) {
      const data = {
        code: this.preauthForm.value.code,
        action: this.preauthForm.value.action,
        diagnosis: this.preauthForm.value.diagnosis,
        actionCode: this.preauthForm.value.actionCode,
        estimatedCost: this.preauthForm.value.estimatedCost,
      };
      this.ordersService.sendPreAuth(this.data.id, data).subscribe(
        succes => {
          this._snackBar.open('Preautoryzacja została dodana', 'Zamknij', {});
          this.dialogRef.close(this.preauthForm.value);
        },
        error => {
          this.error = error;
        }
      );
    }
  }
}
