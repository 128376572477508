import { DatePipe } from '@angular/common';
import { Component, DoCheck, inject, Input, KeyValueDiffers, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Order } from 'dashboard-libs/models/order.model';
import { Preauthorization } from 'dashboard-libs/models/preauthorization.model';

export interface Step { 
  label: string; 
  status: 'completed' | 'current' | 'upcoming' | 'error' | 'grayed-out' | 'bin';
  description: string;
}

@Component({
  selector: 'app-order-progress-stepper',
  standalone: false,
  templateUrl: './order-progress-stepper.component.html',
  styleUrl: './order-progress-stepper.component.scss',
})
export class OrderProgressStepperComponent implements OnInit, DoCheck{

  private datePipe = inject(DatePipe);

  @Input({required: true}) order!: Order;

  currentStep: number = 0;

  steps : Step[] = [];

  private differ: any;

  constructor(private differs: KeyValueDiffers) { }

  ngOnInit(): void {
    this.differ = this.differs.find(this.order).create();
    if(this.order){
      this.initStepperSteps(); 
    } 
  }

  ngDoCheck(): void {
    const changes = this.differ.diff(this.order);
    if (changes) {
      changes.forEachChangedItem((record : any) => {
        if (record.key === 'preauthorization') {
          this.initStepperSteps();
        }
      });
    }
  }

  readonly noAcceptanceStatuses = ['SZKODA CAŁKOWITA', 'ODMOWA', 'BRAK AKCEPTACJI'];

  isPreauthorizationDecision: boolean = false;

  preauthorizationDecision!: string;

  initStepperSteps() : void {
    this.isPreauthorizationDecision = !!(this.order.preauthorization as Preauthorization)?.decision;
    this.preauthorizationDecision = this.isPreauthorizationDecision ? (this.order.preauthorization as Preauthorization).decision : '';
  
    this.steps = [
      { 
        label: 'Przyjęte do naprawy',
        status: this.order.dates.repairUnitAssignDate ? 'completed' : 'grayed-out',
        description: this.order.dates.repairUnitAssignDate ? this.datePipe.transform(this.order.dates.repairUnitAssignDate, 'yyyy-MM-dd | HH:mm') || '' : ''
      },
      { 
        label: 'Wycenione', 
        status: (this.order.costsHistory.length > 1 || this.order.parts.length > 0) ? 'completed' : 'grayed-out',
        description: (this.order.costsHistory.length > 1 || this.order.parts.length > 0) ? '' : '',
      },
      { 
        label: 'Akceptacja wyceny', 
        status: this.isPreauthorizationDecision ? this.noAcceptanceStatuses.includes(this.preauthorizationDecision) ? 'error' : 'completed' : "grayed-out",
        description: this.isPreauthorizationDecision ? this.noAcceptanceStatuses.includes(this.preauthorizationDecision) ? 'Brak akceptacji' : 'Akceptacja' : "",
      },
      {
        label: !!this.order.dates?.repairEndDate && !this.noAcceptanceStatuses.includes(this.preauthorizationDecision)
        ? "Naprawione"
        : this.isPreauthorizationDecision && this.preauthorizationDecision === 'SZKODA CAŁKOWITA'
        ? "Szkoda całkowita"
        : this.isPreauthorizationDecision && this.preauthorizationDecision === 'ODMOWA'
        ? "Odmowa"
        : "Naprawione", 
        status: !!this.order.dates?.repairEndDate && !this.noAcceptanceStatuses.includes(this.preauthorizationDecision)
        ? 'completed'
        : this.isPreauthorizationDecision && this.preauthorizationDecision === 'SZKODA CAŁKOWITA'
        ? 'bin'
        : this.isPreauthorizationDecision && this.preauthorizationDecision === 'ODMOWA'
        ? 'error'
        : 'grayed-out',
        description: ''
      },
      { 
        label: 'Zakończone', 
        status: this.order.dates?.finishDate ? 'completed' : 'grayed-out',
        description: this.order.dates?.finishDate ? this.datePipe.transform(this.order.dates?.finishDate, 'yyyy-MM-dd | HH:mm') || '' : '',
      },
      { 
        label: !!this.order.dates?.closedAt ? 'Rozliczone' : this.order.state == 'canceled' ? 'Anulowane' : 'Rozliczone', 
        status: !!this.order.dates?.closedAt ? 'completed' : this.order.state == 'canceled' ? 'error' : 'grayed-out',
        description: !!this.order.dates?.closedAt ? this.datePipe.transform(this.order.dates?.closedAt, 'yyyy-MM-dd | HH:mm') || '' : this.order.state == 'canceled' ? '' : ''
      }
    ];
  }

  setCurrentStep(step: number) : void {
    this.currentStep = step;
  }
}
