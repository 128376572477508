import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AuthGuard } from 'src/app/shared/guards/access-allowed.guard';
import { UserService } from 'src/app/shared/services/user.service';
import { DatePipe } from '@angular/common';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Clipboard } from '@angular/cdk/clipboard';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
})
export class LogsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  itemsPerPage: number = 50;
  offset: number = 0;
  pageIndex: number = 0;

  error: any;
  dataSource: any;
  dataSourceErrors: any;
  showFilters: boolean = false;
  method = new FormControl();
  relatedOrder = new FormControl();
  responseCode = new FormControl();
  requestPayload = new FormControl();
  responseMessage = new FormControl();
  Array: any = [];
  methodSelected: any = [];

  createdAt = {
    before: new FormControl(),
    after: new FormControl(),
  };
  requestUri = new FormControl();
  filteredValues = {
    relatedOrder: '',
    method: '',
    createdAt: {
      before: '',
      after: '',
    },
    requestUri: '',
    requestPayload: '',
    responseCode: '',
    responseMessage: '',
  };
  displayedColumns: string[] = [
    'Lp',
    'relatedOrder',
    'createdAt',
    'method',
    'responseCode',
    'requestUri',
    'requestPayload',
    'responseMessage',
  ];
  id: any = null;
  constructor(
    public userService: UserService,
    public Auth: AuthGuard,
    private datePipe: DatePipe,
    private clipboard: Clipboard,
    private route: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.clearFilters();
    let params = new HttpParams();
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.filteredValues['relatedOrder'] = this.id;
      this.relatedOrder.setValue(this.id);
    });

    if (this.id != null) {
      params = params.append('relatedOrder.faultNumber', this.id);
    }

    params = params.append('itemsPerPage', this.itemsPerPage);
    if (this.pageIndex != 0) {
      params = params.append('page', this.pageIndex + 1);
    }
    this.userService.getLogsFiltered(params).subscribe((res: any) => {
      this.paginator.length = res['hydra:totalItems'];

      this.dataSource = res['hydra:member'];
      this.relatedOrder.valueChanges.subscribe(positionFilterValue => {
        this.filteredValues['relatedOrder'] = positionFilterValue;
      });

      this.responseCode.valueChanges.subscribe(positionFilterValue => {
        this.filteredValues['responseCode'] = positionFilterValue;
      });

      this.requestPayload.valueChanges.subscribe(positionFilterValue => {
        this.filteredValues['requestPayload'] = positionFilterValue;
      });

      this.responseMessage.valueChanges.subscribe(positionFilterValue => {
        this.filteredValues['responseMessage'] = positionFilterValue;
      });

      this.method.valueChanges.subscribe(positionFilterValue => {
        this.filteredValues['method'] = positionFilterValue;
      });

      this.createdAt.after.valueChanges.subscribe(weightFilterValue => {
        weightFilterValue = this.datePipe.transform(
          weightFilterValue,
          'YYYY-MM-dd HH:mm:ss'
        );
        this.filteredValues['createdAt']['after'] = weightFilterValue;
      });
      this.createdAt.before.valueChanges.subscribe(weightFilterValue => {
        weightFilterValue.setHours(23, 59, 59);
        weightFilterValue = this.datePipe.transform(
          weightFilterValue,
          'YYYY-MM-dd HH:mm:ss'
        );

        this.filteredValues['createdAt']['before'] = weightFilterValue;
      });

      this.requestUri.valueChanges.subscribe(weightFilterValue => {
        this.filteredValues['requestUri'] = weightFilterValue;
      });
    });
  }

  sendFilters() {
    let params = new HttpParams();

    params = params.append('itemsPerPage', this.itemsPerPage);
    if (this.pageIndex != 0) {
      params = params.append('page', this.pageIndex);
    }
    console.log(this.filteredValues);

    if (
      this.filteredValues['relatedOrder'] != null &&
      this.filteredValues['relatedOrder'] != ''
    ) {
      params = params.append(
        'relatedOrder.faultNumber',
        this.filteredValues['relatedOrder']
      );
    }
    if (
      this.filteredValues['method'] != null &&
      this.filteredValues['method'] != ''
    ) {
      this.Array = this.filteredValues['method'];
      this.Array.forEach((element: string | number | boolean) => {
        params = params.append('method[]', element);
      });
    }

    if (
      this.filteredValues['responseCode'] != null &&
      this.filteredValues['responseCode'] != ''
    ) {
      params = params.append(
        'responseCode',
        this.filteredValues['responseCode']
      );
    }

    if (
      this.filteredValues['requestPayload'] != null &&
      this.filteredValues['requestPayload'] != ''
    ) {
      params = params.append(
        'requestPayload',
        this.filteredValues['requestPayload']
      );
    }

    if (
      this.filteredValues['responseMessage'] != null &&
      this.filteredValues['responseMessage'] != ''
    ) {
      params = params.append(
        'responseMessage',
        this.filteredValues['responseMessage']
      );
    }

    if (
      this.filteredValues['createdAt']['before'] != null &&
      this.filteredValues['createdAt']['before'] != ''
    ) {
      params = params.append(
        'createdAt[before]',
        this.filteredValues['createdAt']['before']
      );
    }
    if (
      this.filteredValues['createdAt']['after'] != null &&
      this.filteredValues['createdAt']['after'] != ''
    ) {
      params = params.append(
        'createdAt[after]',
        this.filteredValues['createdAt']['after']
      );
    }

    if (
      this.filteredValues['requestUri'] != null &&
      this.filteredValues['requestUri'] != ''
    ) {
      params = params.append('requestUri', this.filteredValues['requestUri']);
    }

    this.userService.getLogsFiltered(params).subscribe((res: any) => {
      this.paginator.length = res['hydra:totalItems'];
      this.dataSource = new MatTableDataSource(res['hydra:member']);
    });
  }

  clearFilters() {
    this.createdAt.before.setValue('');
    this.createdAt.after.setValue('');
    this.relatedOrder.setValue('');
    this.method.setValue('');
    this.requestUri.setValue('');
    this.sendFilters();
  }

  onPaginateChange(event: PageEvent) {
    this.offset = event.pageIndex;
    this.pageIndex = event.pageIndex;
    this.itemsPerPage = event.pageSize;
    this.sendFilters();
  }

  copyRequestAdress(row: any) {
    this.clipboard.copy(row.value.requestUri);
  }

  copyPayload(row: any) {
    this.clipboard.copy(row.value.requestPayload);
  }

  copyValue(value: any) {
    this.clipboard.copy(value);
  }
}
