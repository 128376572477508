import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { History } from 'dashboard-libs/models/history.model';

@Component({
  selector: 'app-order-info-timeline',
  templateUrl: './order-info-timeline.component.html',
  styleUrl: './order-info-timeline.component.scss',
})
export class OrderInfoTimelineComponent {
  @Input({required: true}) timelineEvents!: History[];
}
