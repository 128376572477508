<div class="timeline-item">
    <div class="timeline-event-date">
        <p>{{date | date: 'YYYY-MM-dd'}}</p>
        <p>{{date | date: 'HH:MM'}}</p>
    </div>
    <div class="timeline-graph-info">
        <span class="timeline-circle" [ngClass]="alertType" [class.lastItem]="isLastItem"></span>
    </div>
    <div class="timeline-content-container">
        <h6>{{title}}</h6>
        <p>{{detailedInfo}}</p>
    </div>
</div>