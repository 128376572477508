import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskText'
})
export class MaskTextPipe implements PipeTransform {
  transform(value: string, isVisible: boolean): string {
    if (!isVisible) {
      return '******';
    }
    return value;
  }
}