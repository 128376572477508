import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { OrdersService } from '../../services/orders.service';
import { faMessage, faPlus, faUser, faWrench } from '@fortawesome/free-solid-svg-icons';
import { AddCommentComponent } from 'src/app/shared/modals/add-comment/add-comment.component';
import { MatDialog } from '@angular/material/dialog';
import { History } from 'dashboard-libs/models/history.model';
import { Order } from 'dashboard-libs/models/order.model';

@Component({
  selector: 'app-order-info-comments',
  templateUrl: './order-info-comments.component.html',
  styleUrl: './order-info-comments.component.scss',
})
export class OrderInfoCommentsComponent {
  @Input({required: true}) order!: Order;

  @Input({required: true}) comments : History[] = [];

  @Output() refetchComments = new EventEmitter<void>();

  public showData!: boolean;

  faPlus = faPlus;

  constructor(
    private orderService: OrdersService,
    public dialog: MatDialog
  ) {}

  addComment(): void {
    this.showData = false;
    const dialogRef = this.dialog.open(AddCommentComponent, {
      data: { id: this.order.id },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(submit => {
      if (submit === 'cancel') return;
      submit = {
        content: submit.comment,
        type: '',
        code: '',
        toPartner: submit.toPartner,
        toOrderSource: submit.toOrderSource,
      };
      this.orderService.sendComment(this.order.id, submit).subscribe({
        next: () => {
          this.refetchComments.emit();
        },
        error: (error) => {
          console.error(error);
        }
      });
    });
  }
}
