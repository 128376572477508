<div class="notifications-window-wrapper" [class.open]="isOpen" (click)="handleClickOutside()"></div>
<div class="notifications-window expanded" [class.open]="isOpen" [class.expanded]="isExpanded">
    <div class="notifications-window-header">
        <div class="notifications-header-content-container">
            <h6>{{title}}</h6>
            <ng-content select="[headerTitleSection]"></ng-content>
        </div>
        <div class="right-side-header-content">
            <button 
                mat-icon-button 
                (click)="toggleNotificationsExpand()"
                class="resize-window-btn"
                color="primary"
            >
                <fa-icon [icon]="isExpanded ? faDown : faUp"/>
            </button>
            <ng-content select="[header]" class="sub-header"></ng-content>
        </div>
    </div>
    <div class="notifications-content-container">
        <ng-content></ng-content>
    </div>
</div>