import { Component, Input } from '@angular/core';
import { faMessage, faUser, faWrench } from '@fortawesome/free-solid-svg-icons';
import { History } from 'dashboard-libs/models/history.model';

@Component({
  selector: 'app-order-info-comment',
  templateUrl: './order-info-comment.component.html',
  styleUrl: './order-info-comment.component.scss',
})
export class OrderInfoCommentComponent {
  faMessage = faMessage;

  faUser = faUser;

  faWrench = faWrench;

  @Input({required: true}) comment! : History;

  constructor() { }
}
