import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { OrdersService } from '../services/orders.service';
import { ActivatedRoute } from '@angular/router';
import { AuthGuard } from 'src/app/shared/guards/access-allowed.guard';
import { ConfirmCancelOrderComponent } from 'src/app/shared/modals/confirm-cancel-order/confirm-cancel-order.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ChangeServiceComponent } from 'src/app/shared/modals/change-service/change-service.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmArchiveOrderComponent } from 'src/app/shared/modals/confirm-archive-order/confirm-archive-order.component';
import { FinishOrderModalComponent } from 'src/app/shared/modals/finish-order-modal/finish-order-modal.component';
import { AssignOrderModalComponent } from 'src/app/shared/modals/assign-order-modal/assign-order-modal.component';
import { AddPreauthModalComponent } from 'src/app/shared/modals/add-preauth-modal/add-preauth-modal.component';
import { EditCostsComponent } from 'src/app/shared/modals/edit-costs/edit-costs.component';
import { EditPartsComponent } from 'src/app/shared/modals/edit-parts/edit-parts.component';
import { Order } from 'dashboard-libs/models/order.model';
import { Workshop } from 'dashboard-libs/models/workshop.model';
import { History } from 'dashboard-libs/models/history.model';
import { Preauthorization } from 'dashboard-libs/models/preauthorization.model';
import { calculateDayTimeDifference } from 'src/app/shared/helpers/helpers';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Policy } from 'dashboard-libs/models/policy.model';
import { ShowDataComponent } from 'src/app/shared/modals/show-data/show-data.component';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';

@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss'],
})
export class OrderItemComponent implements OnInit, OnChanges {
  public order!: Order;
  public history: any;
  public showData!: boolean;
  public tab: string = 'details';
  public timelineEvents!: History[];
  public comments!: History[];
  public preauthorizationData! : Preauthorization;
  public orderProcessingTime: number = 0;
  public orderLiquidationTime: number = 0;
  public policy : any;
  public isUserDetailsVisible: boolean = false;

  faEye = faEye;

  faEyeSlash = faEyeSlash;

  constructor(
    private ordersService: OrdersService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    public Auth: AuthGuard,
    public dialog: MatDialog,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.getOrderDetails();
    this.loadOrderHistory();
    this.translate.onLangChange.subscribe(() => this.loadOrderHistory());
  }

  toggleUserDetailsVisibility(e: Event) : void {
    e.preventDefault();

    if(!this.isUserDetailsVisible){
      const dialogRef = this.dialog.open(ShowDataComponent, {
        disableClose: true,
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'show') {
          this.isUserDetailsVisible = true;
        }
      });
    }
    else{
      this.isUserDetailsVisible = !this.isUserDetailsVisible;
    }
  }

  calculateOrderExecutionTimeDetails() : void {
    this.orderProcessingTime = calculateDayTimeDifference(this.order.incident.eventDate);
    this.orderLiquidationTime = calculateDayTimeDifference(this.order.createdAt)
  }

  getOrderDetails() : void {
    this.ordersService.getOrderById(this.route.snapshot.params['id']).subscribe({
      next: (resData) => {
        this.order = resData;
        if(typeof this.order.workshop === "string") {
          this.getOrderWorkshopDetails();
        }
        this.fetchOrderPreAuthData();
        this.calculateOrderExecutionTimeDetails();
        this.getPolicy();
        this.breadcrumbService.setBreadCrumbDetails(this.order?.faultNumber);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  getPolicy() : void {
    this.ordersService.getPolicyByName(this.order.insuranceNumber)
      .subscribe({
        next: (policyData : Policy) => {
          this.policy = policyData;
        },
        error: (error) => {
          console.error(error)
        }
      });
  }

  loadOrderHistory() : void {
    const orderId = this.route.snapshot.params['id'];
    this.ordersService.getOrderHistory(orderId).subscribe({
      next: (orderHistory: any) => {
        this.history = orderHistory['hydra:member'];
        this.fetchHistoryData();
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  fetchHistoryData() : void {
    this.timelineEvents = [];
    this.comments = [];
    
    if (this.history) {
      this.history.forEach((historyComment: History) => {
        if (historyComment.eventType === 'activityLog') {
          this.timelineEvents.push(historyComment);
        }
        else if (historyComment.eventType === 'comment') {
          this.comments.push(historyComment);
        }
      });
    }
  }

  getOrderWorkshopDetails() : void {
    this.ordersService.getDataFromIRI(this.order.workshop as string).subscribe({
      next: (workshopData) => {
        this.order.workshop = workshopData as Workshop;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  cancel() {
    const dialogRef = this.dialog.open(ConfirmCancelOrderComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.ordersService
          .cancelOrder(this.route.snapshot.params['id'])
          .subscribe(res => {
            this.refresh();
          });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) : void{
    if (changes.order.currentValue) {
      this.fetchOrderPreAuthData();
    }
  }

  fetchOrderPreAuthData() : void {
    if(this.order){
      this.ordersService.getOrderPreAuth(this.order.id).subscribe({
        next: (preauthorizationData) => {
          this.order.preauthorization = preauthorizationData as Preauthorization;
          this.preauthorizationData = preauthorizationData as Preauthorization;
        },
        error: (error) => {
          console.error(error)
        }
      });
    }
  }

  refresh() {
    this.ngOnInit();
  }

  changeService() {
    const dialogRef = this.dialog.open(ChangeServiceComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result[0] === 'confirm') {
        this.ordersService
          .updateServiceForOrder(this.order.id, result[1])
          .subscribe();
        this.refresh();
      }
    });
  }

  recalculate() {
    this.ordersService
      .recalculateOrder(this.route.snapshot.params['id'])
      .subscribe(res => {
        if (
          res ===
          'Order has no costs stored - recalculation of margins and service fee is not possible'
        ) {
          this._snackBar.open(
            'Zamówienie nie ma zapisanych kosztów - przeliczenie marż i opłat serwisowych nie jest możliwe',
            'Zamknij',
            {}
          );
        }

        if (res == 'Costs successfully recalculated in DRS.') {
          this._snackBar.open(
            'Koszty pomyślnie przkalkulowane w DRS',
            'Zamknij',
            {}
          );
        }

        if (
          res != 'Costs successfully recalculated in DRS.' &&
          res !=
            'Order has no costs stored - recalculation of margins and service fee is not possible'
        ) {
          this._snackBar.open(res.toLocaleString() as string, 'Zamknij', {});
        }
      });
    this.ngOnInit();
  }

  updatePolicy() {
    this.ordersService.updatePolicyOfOrder(this.order.id).subscribe(res => {
      if (res == 'Insurance has been updated successfully') {
        this._snackBar.open(
          'Dane polisy pomyślnie zaktualizowane w DRS',
          'Zamknij',
          {}
        );
      } else {
        this._snackBar.open(res.toLocaleString() as string, 'Zamknij', {});
      }

      this.ngOnInit;
    });
  }

  updateData() {
    this.ordersService.updateDataOfOrder(this.order.id).subscribe(res => {
      if (res == 'Order data has been successfully updated in DRS') {
        this._snackBar.open(
          'Dane pomyślnie zaktualizowane w DRS',
          'Zamknij',
          {}
        );
      } else if (
        res ==
        'Order has no costs stored - recalculation of margins and service fee is not possible'
      ) {
        this._snackBar.open(
          'Zamówienie nie ma zapisanych kosztów - przeliczenie marż i opłat serwisowych nie jest jużwe',
          'Zamknij',
          {}
        );
      } else {
        this._snackBar.open(res.toLocaleString() as string, 'Zamknij', {});
      }

      this.ngOnInit();
    });
  }
  archiveOrder() {
    const dialogRef = this.dialog.open(ConfirmArchiveOrderComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.ordersService
          .archiveOrder(this.route.snapshot.params['id'])
          .subscribe(res => {
            if (res === 'Order has been successfully moved to archive') {
              this._snackBar.open(
                'Zlecenie zostało zarchiwizowane',
                'Zamknij',
                {}
              );
            } else if (
              res ===
              'Order is already in archive - it is impossible to move it there again'
            ) {
              this._snackBar.open(
                'Zlecenie jest już w archiwum - nie jest ono możliwe do przeniesiania do archiwum',
                'Zamknij',
                {}
              );
            } else {
              this._snackBar.open(
                res.toLocaleString() as string,
                'Zamknij',
                {}
              );
            }
            this.ngOnInit();
          });
      }
    });
  }

  beginRepair() {
    this.ordersService.sendBeginRepair(this.order.id).subscribe(res => {
      this._snackBar.open('Naprawa została rozpoczęta', 'Zamknij', {});

      setTimeout(() => {
        this.ngOnInit();
      }, 2000);
    });
  }

  endRepair() {
    this.ordersService.sendEndRepair(this.order.id).subscribe(res => {
      this._snackBar.open('Naprawa została zakończona', 'Zamknij', {});

      setTimeout(() => {
        this.ngOnInit();
      }, 2000);
    });
  }

  addPreAuth() {
    const dialogRef = this.dialog.open(AddPreauthModalComponent, {
      disableClose: true,
      data: this.order,
    });
    setTimeout(() => {
      this.ngOnInit();
    }, 2000);
  }

  finishOrder() {
    const dialogRef = this.dialog.open(FinishOrderModalComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.code) {
        const data = {
          code: result.code,
          action: result.action,
          repairDescription: result.repairDescription,
        };
        this.ordersService
          .sendFinishOrder(this.order.id, data)
          .subscribe(res => {
            this._snackBar.open('Zlecenie zostało zakończone', 'Zamknij', {});

            setTimeout(() => {
              this.ngOnInit();
            }, 2000);
          });
      }
    });
  }

  assignOrder() {
    const dialogRef = this.dialog.open(AssignOrderModalComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.name) {
        this.ordersService.assignOrder(this.order.id, result).subscribe(res => {
          this._snackBar.open('Zlecenie zostało przypisane', 'Zamknij', {});

          setTimeout(() => {
            this.ngOnInit();
          }, 2000);
        });
      }
    });
  }

  openLogsOfOrder() {
    window.open('/logs/' + this.order.faultNumber, '_blank');
  }

  editCosts() {
    const dialogRef = this.dialog.open(EditCostsComponent, {
      data: {
        order: this.order,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'success') {
        this.ngOnInit();
      }
    });
  }

  editParts() {
    const dialogRef = this.dialog.open(EditPartsComponent, {
      data: {
        order: this.order,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'success') {
        this.ngOnInit();
      }
    });
  }
}
