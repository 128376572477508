@if(order){
  <div class="order-progress-stepper">
      <mat-stepper [linear]="true" [selectedIndex]="currentStep" labelPosition="bottom" #stepper>
          @for (step of steps; track step.label; let i = $index) {
            <mat-step [editable]="false" [state]="step.status">
              <ng-template matStepLabel>
                <p class="step-title">{{ step.label | capitalize}}</p>
                <span class="step-description">{{ step.description | capitalize}}</span>
              </ng-template>
            </mat-step>
          }
        
          <ng-template matStepperIcon="completed">
            <mat-icon>check</mat-icon>
          </ng-template>
          <ng-template matStepperIcon="current">
            <mat-icon>edit</mat-icon>
          </ng-template>
          <ng-template matStepperIcon="upcoming">
            <mat-icon>remove</mat-icon>
          </ng-template>
          <ng-template matStepperIcon="error">
            <mat-icon>warning</mat-icon>
          </ng-template>
          <ng-template matStepperIcon="bin">
            <mat-icon>restore_from_trash</mat-icon>
          </ng-template>
          <ng-template matStepperIcon="grayed-out">
          </ng-template>
      </mat-stepper>
  </div>
}