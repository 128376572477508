@if(!(!preauthorizationData || (preauthorizationData && !preauthorizationData.decision && !preauthorizationData.requestedAt))){
  <div class="order-status-info"
    [class.pending]="preauthorizationData && !preauthorizationData.decision && preauthorizationData.requestedAt"
    (click)="(preauthorizationData.decision || preauthorizationData) && openPreAuth()"
  >
    <p>
      <b>Preautoryzacja</b>
      <span class="order-status-info-text-content">
        @if(preauthorizationData.decision){
          <span>
            {{ (preauthorizationData.code | translate) }} 
            @if(preauthorizationData.code){
              <span>-</span>
            }
            {{ preauthorizationData.decision | translate }}
          </span>
        }
        @if(preauthorizationData && !preauthorizationData.decision && preauthorizationData.requestedAt){
          <span>
            {{ (preauthorizationData.code | translate) + " - " + ('waiting_for_authorization' | dashIfEmpty | translate) }}
          </span>
        }
      </span>
    </p>
    @if(preauthorizationData.decision){
      <p>Data decyzji: {{preauthorizationData.preauthorizedAt | date: 'YYYY-MM-dd'}}</p>
    } 
    @else {
      <p>Data otrzymania: {{preauthorizationData.requestedAt | date: 'YYYY-MM-dd'}}</p>
    }
  </div>
}