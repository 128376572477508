import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { faDownLeftAndUpRightToCenter, faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-notifications-window',
  templateUrl: './notifications-window.component.html',
  styleUrl: './notifications-window.component.scss',
})
export class NotificationsWindowComponent{
  @Input({required: true}) title!: string;
  @Input({required: true}) isOpen!: boolean;

  faUp = faUpRightAndDownLeftFromCenter;

  faDown = faDownLeftAndUpRightToCenter;

  public isExpanded: boolean = false; 

  @Output() onClickOutside = new EventEmitter<void>();

  constructor() {
  }

  toggleNotificationsExpand() : void{
    this.isExpanded = !this.isExpanded;
  }

  handleClickOutside() : void {
    this.onClickOutside.emit();
  }
}
