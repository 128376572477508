<div class="comment-item">
    @switch (comment.eventData.type) {
        @case ("drs") {
            <span class="comment-icon service" [style.background]="'#4B5675'">
                <fa-icon [icon]="faMessage"/>
            </span>
        }
        @case ("provider") {
            <span class="comment-icon service" [style.background]="'#2972B7'">
                <fa-icon [icon]="faUser"/>
            </span>
        }
        @case ("partner") {
            <span class="comment-icon service" [style.background]="'#F26B2B'">
                <fa-icon [icon]="faWrench"/>
            </span>
        }
        @default {}
    }
    <div class="comment-item-content-container">
        <h6>{{comment.eventData.type | uppercase}} - {{comment.createdBy || "Brak autora"}}</h6> <!-- HERE -->
        <p>{{comment.eventData.content}}</p>
        <span>{{comment.eventDate | customDate}}</span>
    </div>
</div>