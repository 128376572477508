import { Component, Input } from '@angular/core';
import { faInbox, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-no-content',
  templateUrl: './no-content.component.html',
  styleUrl: './no-content.component.scss',
})
export class NoContentComponent {

  @Input({required: true}) title!: string;
  
  @Input() icon?: IconDefinition;
  
  faInbox = faInbox
}
