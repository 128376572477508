<div>
  <mat-tab-group
    class="no_pointer"
    mat-stretch-tabs="false"
    dynamicHeight
    disableRipple
    disabled>
    <mat-tab label="Serwis">
      <div class="actions">
        <button mat-button [matMenuTriggerFor]="menu" mat-stroked-button>
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <!-- <button mat-menu-item
            *ngIf="Auth.isViewAllowed(['ROLE_ORDER_DETAILS_EDIT', 'ROLE_ADMIN'])"><mat-icon>edit</mat-icon>Zmień
            serwis</button> -->

          <button mat-menu-item (click)="removeUser(service.id)">
            <mat-icon>delete</mat-icon>Usuń
          </button>
          <button *ngIf="!service?.active" mat-menu-item (click)="activate()">
            <mat-icon class="green">power_settings_new</mat-icon>Aktywuj
          </button>
          <button *ngIf="service?.active" mat-menu-item (click)="deactivate()">
            <mat-icon class="red">power_settings_new</mat-icon>Dezaktywuj
          </button>
        </mat-menu>
      </div>
      <div class="flex">
        <div class="left-flex">
          <div class="card">
            <div class="card-title">
              <p>{{ 'services-front.contractor_data' | translate }}</p>
              <button
                (click)="editService()"
                mat-stroked-button
                *ngIf="
                  Auth.isViewAllowed([
                    'ROLE_WORKSHOP_SERVICE_EDIT',
                    'ROLE_ADMIN',
                  ])
                ">
                <p>{{ 'services-front.edit' | translate }}</p>
              </button>
            </div>
            <hr class="custom-hr" />
            <div class="card-content">
              <div class="left-site">
                <div class="content-item">
                  <p>{{ 'services-front.agreement_number' | translate }}</p>
                  <p>{{ service?.contractNumber }}</p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p>
                    {{ 'services-front.bank_account_number(IBAN)' | translate }}
                  </p>
                  <p>{{ service?.bankAccountNumber | iban }}</p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p>{{ 'services-front.nip' | translate }}</p>
                  <p>{{ service?.taxNumber }}</p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p>{{ 'services-front.regon' | translate }}</p>
                  <p>{{ service?.regon }}</p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p>{{ 'services-front.state' | translate }}</p>
                  <p>{{ service?.active ? 'Aktywny' : 'Nieaktywny' }}</p>
                </div>

                <hr class="custom-hr" />
                <div class="content-item">
                  <p>{{ 'services-front.added' | translate }}</p>
                  <p>{{ service?.createdAt | date: 'YYYY.MM.dd HH:mm' }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              {{ 'services-front.address' | translate }}
            </div>
            <hr class="custom-hr" />
            <div class="card-content">
              <div class="left-site">
                <div class="content-item">
                  <p>{{ 'services-front.street' | translate }}</p>
                  <p>{{ service?.address?.streetName }}</p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p>{{ 'services-front.house_number' | translate }}</p>
                  <p>{{ service?.address?.buildingNumber }}</p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p>{{ 'services-front.city' | translate }}</p>
                  <p>{{ service?.address?.city }}</p>
                </div>
                <hr class="custom-hr" />
                <div class="content-item">
                  <p>{{ 'services-front.postal_code' | translate }}</p>
                  <p>{{ service?.address?.postalCode | zipCode }}</p>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="card">
        <div class="card-title">
          {{ "services-front.working_area" | translate }}
          <button (click)="editArea()" mat-stroked-button
            *ngIf="Auth.isViewAllowed(['ROLE_WORKSHOP_MANAGE', 'ROLE_ADMIN'])">
            {{ "services-front.edit" | translate }}
          </button>
        </div>
        <hr class="custom-hr" />
        <div class="card-content">
          <div class="left-site">
            <div class="content-item types">
              <p class="mr-10">
                <button class="button-owner">02-795 Warszawa | mock</button>
              </p>
              <p>
                <button class="button-owner">02-795 Warszawa | mock</button>
              </p>
            </div>
          </div>
        </div>
      </div> -->
        </div>
        <div class="right-flex">
          <div class="card">
            <div class="card-title">
              {{ 'services-front.contact' | translate }}
              <button
                (click)="addContact()"
                mat-stroked-button
                *ngIf="
                  Auth.isViewAllowed(['ROLE_WORKSHOP_MANAGE', 'ROLE_ADMIN'])
                ">
                {{ 'services-front.add_contact' | translate }}
              </button>
            </div>
            <hr class="custom-hr" />
            <div class="card-content">
              <div class="left-site">
                <div *ngFor="let contact of contacts; let i = index">
                  <div class="content-item">
                    <p>
                      <mat-icon
                        class="arrow-down"
                        (click)="showContactDetails(i)"
                        >keyboard_arrow_down</mat-icon
                      >
                      {{ contact?.contactDetails?.name }}
                      {{ contact?.contactDetails?.surname }}
                    </p>
                    <p>
                      <button class="button-owner">
                        {{
                          contact?.contactDetails?.type === 'generic'
                            ? 'Zwykły'
                            : 'Właściciel'
                        }}
                      </button>
                      <mat-icon (click)="showActions(contact?.id)"
                        >more_vert</mat-icon
                      >
                    </p>
                  </div>
                  <div
                    (clickOutside)="removeActions()"
                    class="actions-dialog"
                    *ngIf="showActionsId == contact?.id">
                    <p (click)="deleteContact(contact)" class="click">
                      <mat-icon>delete</mat-icon
                      >{{ 'services-front.remove' | translate }}
                    </p>
                    <p (click)="editContact(contact)" class="click">
                      <mat-icon>edit</mat-icon
                      >{{ 'services-front.edit' | translate }}
                    </p>
                  </div>
                  <div class="contact-details" *ngIf="selectedDetails == i">
                    <div class="content-item">
                      <p>{{ 'services-front.email_address' | translate }}</p>
                      <p>{{ contact?.contactDetails?.email }}</p>
                    </div>
                    <hr class="custom-hr" />
                    <div
                      class="content-item"
                      *ngIf="contact?.contactDetails?.mobileNumber">
                      <p>{{ 'services-front.phone_number' | translate }}</p>
                      <p>{{ contact?.contactDetails?.mobileNumber }}</p>
                    </div>
                    <hr class="custom-hr" />
                    <div
                      class="content-item"
                      *ngIf="contact?.contactDetails?.phoneNumber">
                      <p>
                        {{
                          'services-front.phone_number(additional)' | translate
                        }}
                      </p>
                      <p>{{ contact?.contactDetails?.phoneNumber }}</p>
                    </div>
                  </div>
                </div>
                <hr class="custom-hr" />
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <p>{{ 'services-front.margin' | translate }}</p>
              <button
                (click)="editMargin()"
                mat-stroked-button
                *ngIf="
                  Auth.isViewAllowed([
                    'ROLE_WORKSHOP_MARGIN_EDIT',
                    'ROLE_ADMIN',
                  ])
                ">
                <p>{{ 'services-front.edit' | translate }}</p>
              </button>
            </div>
            <hr class="custom-hr" />
            <div class="card-content">
              <div class="left-site">
                <div class="content-item">
                  <p>{{ 'services-front.margin_name' | translate }}</p>
                  <p>Domyślna</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              {{ 'supported_brands' | translate }}
              <button
                (click)="editBrands()"
                mat-stroked-button
                *ngIf="
                  Auth.isViewAllowed(['ROLE_WORKSHOP_MANAGE', 'ROLE_ADMIN'])
                ">
                <p>{{ 'services-front.edit' | translate }}</p>
              </button>
            </div>
            <hr class="custom-hr" />
            <div class="card-content">
              <div class="left-site">
                <div class="content-item types">
                  <p
                    class="mr-10"
                    *ngFor="let supportedBrand of service?.brand">
                    <button class="button-owner">
                      {{ supportedBrand.name }}
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Jednostki naprawcze">
      <app-repair-unit [workshopId]="workshopId"></app-repair-unit>
    </mat-tab>
  </mat-tab-group>
</div>
