<ng-container>
  <mat-card>
    <div class="card-title">{{ 'my_data' | translate }}</div>
    <mat-card-content class="card-content">
      <div class="left-site">
        <p class="title">{{ 'name_and_surname' | translate }}</p>
        <p class="data">
          {{ data?.name | dashIfEmpty }} {{ data?.surname | dashIfEmpty }}
        </p>
        <hr class="gray-line" />
        <p class="title">{{ 'email' | translate }}</p>
        <p class="data">{{ data?.email | dashIfEmpty }}</p>
        <hr class="gray-line" />
        <p class="title">{{ 'workshop' | translate }}</p>
        <p class="data">{{ data?.workshop?.name | dashIfEmpty }}</p>
      </div>
      <div class="right-site">
        <p class="title">{{ 'user_group' | translate }}</p>
        <p class="data">
          {{ data?.keycloakGroups[0]?.name | translate | dashIfEmpty }}
        </p>
        <hr class="gray-line" />
        <p class="title">{{ 'Admin' | translate }}</p>
        <p class="data">
          <span *ngIf="data?.admin">{{ 'yes' | translate }}</span
          ><span *ngIf="!data?.admin">{{ 'no' | translate }}</span>
        </p>
        <hr class="gray-line" />
        <p class="title">{{ 'api_partner_access' | translate }}</p>
        <p class="data">
          <span *ngIf="data?.api_partner">{{ 'yes' | translate }}</span
          ><span *ngIf="!data?.api_partner">{{ 'no' | translate }}</span>
        </p>
        <!-- <p class="title">{{ 'roles' | translate }}</p>
        <p class="data" *ngFor="let role of data?.roles">
          {{ role | translate | dashIfEmpty }}
        </p> -->
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
