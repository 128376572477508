import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { OrdersService } from 'src/app/pages/orders/services/orders.service';
import { NotificationOrderHydraMember } from '../../../../dashboard-libs/models/notificationOrder.model';
import { NotificationPreAuthHydraMember } from '../../../../dashboard-libs/models/notificationPreAuth.model';
import { NotificationComment } from '../../../../dashboard-libs/models/notificationComment.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private _commentsCategory : BehaviorSubject<"UNREAD" | "ALL"> = new BehaviorSubject<"UNREAD" | "ALL">("UNREAD");
  
  public get commentsCategory$() : Observable<"UNREAD" | "ALL"> {
    return this._commentsCategory.asObservable();
  }

  public setMessagesCategory(category: "UNREAD" | "ALL") : void {
    this._commentsCategory.next(category);
  }

  private userInfo$ = this.userService.getUserInfo().pipe(shareReplay(1));

  private readonly commentsLimit = 10;
  
  private commentsOffset = 0;

  private intervalId?: any;

  private _orderNotifications : BehaviorSubject<NotificationOrderHydraMember[]> = new BehaviorSubject<NotificationOrderHydraMember[]>([]);
  
  private _commentNotifications : BehaviorSubject<NotificationComment[]> = new BehaviorSubject<NotificationComment[]>([]);

  private _preAuthorizationNotifications : BehaviorSubject<NotificationPreAuthHydraMember[]> = new BehaviorSubject<NotificationPreAuthHydraMember[]>([]);

  private _unreadCommentsCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private _unreadOrdersCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private _unreadPreAuthCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  get orderNotifications$() : Observable<NotificationOrderHydraMember[]> {
    return this._orderNotifications.asObservable();
  }

  get commentNotifications$() : Observable<NotificationComment[]> {
    return this._commentNotifications.asObservable();
  }

  get preAuthorizationNotifications$() : Observable<NotificationPreAuthHydraMember[]> {
    return this._preAuthorizationNotifications.asObservable();
  }

  get unreadCommentsCount$(): Observable<number> {
    return this._unreadCommentsCount.asObservable();
  }

  get unreadOrdersCount$(): Observable<number> {
    return this._unreadOrdersCount.asObservable();
  }

  get unreadPreAuthCount$(): Observable<number> {
    return this._unreadPreAuthCount.asObservable();
  }

  public resetPagination(): void {
    this.commentsOffset = 0;
  }

  fetchCommentNotifications(filter: 'ALL' | 'UNREAD'): Observable<NotificationComment[]> {
    const comments$ =
      filter === 'ALL'
        ? this.ordersService.getNextComments(this.commentsOffset, this.commentsLimit)
        : this.ordersService.getNextNewComments(this.commentsOffset, this.commentsLimit);

    return comments$.pipe(
      switchMap((comments: any) =>
        forkJoin({
          comments: of(comments['hydra:member']),
          user: this.userInfo$,
        })
      ),
      map(({ comments, user }) => {
        return comments.filter(
          (comment: any) =>
            comment.createdBy !== `${user?.name} ${user?.surname}`
        );
      })
    );
  }

  fetchNextComments(filter: 'ALL' | 'UNREAD'): Observable<NotificationComment[]> {
    const comments$ =
      filter === 'ALL'
        ? this.ordersService.getNextComments(this.commentsOffset, this.commentsLimit)
        : this.ordersService.getNextNewComments(this.commentsOffset, this.commentsLimit);

    return comments$.pipe(
      map((comments: any) => comments['hydra:member'])
    );
  }

  updatePagination(): void {
    this.commentsOffset += this.commentsLimit;
  }

  constructor(private ordersService: OrdersService, private userService: UserService) {
    this.fetchUnreadNotifications();
    this.startFetchingUnreadNotifications();
    this.subscribeToCounters();
  }

  private startFetchingUnreadNotifications(): void {
    this.intervalId = setInterval(() => {
      this.fetchUnreadNotifications();
    }, 5000);
  }

  stopFetchingUnreadNotifications(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  fetchUnreadNotifications() {
    this.ordersService.getUnreadNotificationsCount().subscribe({
      next: (unreadNotifications) => {
        this.updateCommentsCount(unreadNotifications.unreadComments);
        this.updateUnreadOrdersCount(unreadNotifications.unreadOrders);
        this.updateUnreadPreauthorizationsCount(unreadNotifications.unreadPreauthorizations);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  private updateCommentsCount(countValue: number) : void {
    if(countValue !== this._unreadCommentsCount.getValue()){
      this._unreadCommentsCount.next(countValue);
    }
  }

  private updateUnreadOrdersCount(countValue: number) : void {
    if(countValue !== this._unreadOrdersCount.getValue()){
      this._unreadOrdersCount.next(countValue);
    }
  }

  private updateUnreadPreauthorizationsCount(countValue: number) : void {
    if(countValue !== this._unreadPreAuthCount.getValue()){
      this._unreadPreAuthCount.next(countValue);
    }
  }

  private subscribeToCounters(): void {
    this._unreadOrdersCount.subscribe(() => this.fetchOrderNotifications());
    this._unreadPreAuthCount.subscribe(() => this.fetchPreAuthorizationNotifications());
  }

  fetchOrderNotifications() : void {
    this.ordersService.getNewOrders().subscribe({
      next: (ordersData) => {
        this._orderNotifications.next(ordersData['hydra:member']);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  fetchPreAuthorizationNotifications() : void {
    this.ordersService.getNewPreAuth().subscribe({
      next: (preAuthData) => {
        this._preAuthorizationNotifications.next(preAuthData['hydra:member']);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
}
