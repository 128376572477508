@if(isLoading){
  <div class="spinner-container">
    <mat-spinner/>
  </div>
}
@else if (preAuth && preAuth.length){
  @for (preAuthItem of preAuth; track $index) {
    <app-notification-item
      [routerLink]="'orders/' + preAuthItem.orderId"
      [queryParams]="{ showPreAuth: true }"
      [notificationType]="preAuthItem.preauthorizationActivityType === 'request' ? 'partner' : 'provider'"
      [title]="preAuthItem.orderFaultNumber"
      [description]="preAuthItem.preauthorizationDecision"
      [time]="preAuthItem.preauthorizationUpdateAt | timeAgo | translate"
      (click)="onDismissOpenModal()"
    />
  }
}
@else{
  <app-no-content
    title="Brak nowych preautoryzacji"
  />
}