<div class="main-documents-content">
  @if(Auth.isViewAllowed(['ROLE_ORDER_DOCUMENTS_VIEW', 'ROLE_ADMIN'])){
    <app-order-info-details title="Dokumenty" [isOpen]="true"> 
      <div header>
        <button
          mat-button
          (click)="addAttachment()"
          *ngIf="
            Auth.isViewAllowed(['ROLE_ORDER_DOCUMENTS_ADD', 'ROLE_ADMIN'])
          ">
        <fa-icon [icon]="faPlus"/> Dodaj dokument
        </button>
      </div>
      <div class="card">
        <div class="table">
          <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="fileName">
              <th mat-header-cell *matHeaderCellDef>Nazwa dokumentu</th>
              <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>
  
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>Typ</th>
              <td mat-cell *matCellDef="let element">
                {{ element?.typeClaimsName }}
              </td>
            </ng-container>
  
            <ng-container matColumnDef="createdAt">
              <th mat-header-cell *matHeaderCellDef>Data dodania</th>
              <td mat-cell *matCellDef="let element">
                {{ element.createdAt | date: 'YYYY-MM-dd | HH:mm' }}
              </td>
            </ng-container>
  
            <ng-container matColumnDef="size">
              <th mat-header-cell *matHeaderCellDef>Rozmiar</th>
              <td mat-cell *matCellDef="let element">
                {{ element.size ? (element.size | fileSize) : 'N/A' }}
              </td>
            </ng-container>
  
            <ng-container matColumnDef="partner">
              <th mat-header-cell *matHeaderCellDef>Partner</th>
              <td mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.partner">checked</mat-icon>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="orderSource">
              <th mat-header-cell *matHeaderCellDef>OrderSource</th>
              <td mat-cell *matCellDef="let element" class="center">
                <mat-icon *ngIf="element.orderSource">checked</mat-icon>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Akcje</th>
              <td mat-cell *matCellDef="let element">
                <mat-icon
                  class="icon"
                  (click)="download(element.id, element.fileName)"
                  aria-label="Download document"
                  matTooltip="Download document"
                  >download</mat-icon
                >
                <mat-icon
                  class="icon"
                  (click)="showAttachment(element.id)"
                  aria-label="View document"
                  matTooltip="View document"
                  >remove_red_eye</mat-icon
                >
              </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  
            <tr
              (dblclick)="showAttachment(row.id)"
              class="hovered"
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              aria-label="Double click to view document details"
              role="button"
              tabindex="0"></tr>
          </table>
          <div class="empty-table-view" *ngIf="dataSource.data.length === 0">
            <p>{{ 'no_documents' | translate }}</p>
          </div>
        </div>
      </div>
    </app-order-info-details>
  }
</div>