<ng-container class="main-card">
  <mat-card>
    <button
      mat-button
      class="primary-btn button"
      (click)="addRepairUnit()"
      [hidden]="!showButtons">
      Dodaj jednostke naprawczą
    </button>

    <button
      mat-button
      class="primary-btn button"
      (click)="addRepairUnitAsAdmin()"
      [hidden]="showButtons && Auth.isViewAllowed(['ROLE_ADMIN'])">
      Dodaj jednostke naprawczą
    </button>
    <div>
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'name' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.name | dashIfEmpty }}
          </td>
        </ng-container>
        <ng-container matColumnDef="street">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'street' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.address.street | dashIfEmpty }}
          </td>
        </ng-container>
        <ng-container matColumnDef="buildingNumber">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'buildingNumber' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.address.buildingNumber | dashIfEmpty }}
          </td>
        </ng-container>
        <ng-container matColumnDef="apartmentNumber">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'apartmentNumber' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.address.apartmentNumber | dashIfEmpty }}
          </td>
        </ng-container>
        <ng-container matColumnDef="postalCode">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'postalCode' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.address.postalCode | dashIfEmpty }}
          </td>
        </ng-container>
        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'city' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.address.city | dashIfEmpty }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div> </mat-card
></ng-container>
