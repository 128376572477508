import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthGuard } from 'dashboard-libs/guards/access-allowed.guard';
import { OrdersService } from 'src/app/pages/orders/services/orders.service';

@Component({
  selector: 'app-add-repair-unit',
  templateUrl: './add-repair-unit.component.html',
  styleUrls: ['./add-repair-unit.component.scss'],
})
export class AddRepairUnitComponent {
  @Inject(MAT_DIALOG_DATA) public data: any;
  name: any;
  street: any;
  buildingNumber: any;
  apartmentNumber: any;
  postalCode: any;
  city: any;
  submitted: boolean = false;
  error: any;
  public form!: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<AddRepairUnitComponent>,
    public Auth: AuthGuard,
    public orderService: OrdersService,
    public fb: FormBuilder,
    public MatSnackBar: MatSnackBar
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      street: ['', Validators.required],
      buildingNumber: ['', Validators.required],
      apartmentNumber: [''],
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
    });
  }

  onSubmit() {
    this.submitted = true;
    const data = {
      name: this.form.value.name,
      street: this.form.value.street,
      buildingNumber: this.form.value.buildingNumber,
      apartmentNumber: this.form.value.apartmentNumber,
      postalCode: this.form.value.postalCode,
      city: this.form.value.city,
    };
    if (this.form.valid) {
      this.addRepairUnit(data).subscribe(
        succes => {
          this.dialogRef.close();
        },
        error => {
          this.error = error;
        }
      );
    }
  }

  addRepairUnit(data: any) {
    return this.orderService.addRepairUnit(data);
  }
}
