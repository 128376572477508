<h1 mat-dialog-title class="text-center">
  {{ "confirm_to_view_more_data" | translate }}
</h1>
<div mat-dialog-content>
  <p class="text-center p-content">
    {{
      "you_would_like_to_view_your_customers_personal_information" | translate
    }},
    {{
      "to_access_detailed_information_confirm_you_want_to_access_the_data"
        | translate
    }}
  </p>
</div>
<div class="text-center" mat-dialog-actions>
  <button
    mat-button
    class="primary-btn"
    color="primary"
    [mat-dialog-close]="'show'"
  >
    {{ "show_data" | translate }}
  </button>
</div>
