<app-order-info-details title="Komentarze" [isOpen]="true"> 
    <div header>
      <button (click)="addComment()" mat-button><fa-icon [icon]="faPlus"/> Dodaj</button>
    </div>
    <div class="comments-container">
      @if(comments && comments.length){
        @for (comment of comments; track comment.eventData) {
          <app-order-info-comment
            [comment]="comment"
          />
        } 
      }
      @else{
        <app-no-content
          title="Brak komentarzy"
        />
      }
    </div>
</app-order-info-details>