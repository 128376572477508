import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthGuard } from '../../guards/access-allowed.guard';
@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss'],
})
export class AddCommentComponent {
  comment: any;
  toOrderSource: boolean = false;
  toPartner: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<AddCommentComponent>,
    public Auth: AuthGuard
  ) {}

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close({
      comment: this.comment,
      toOrderSource: this.toOrderSource,
      toPartner: this.toPartner,
    });
  }
}
