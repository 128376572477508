@if(orders && orders.length){
  @for (order of orders; track $index) {
    <app-notification-item
      [routerLink]="'/orders/' + order.id"
      [notificationType]="'provider'"
      [title]="'Wagas | ' + order.faultNumber"
      [time]="order.updatedAt"
      (click)="onDismissOpenModal()"
    />
  }
}
@else {
  <app-no-content
    title="Brak nowych zleceń"
  />
}