<h1 mat-dialog-title class="title">Dodaj jednostkę naprawczą</h1>
<hr class="custom-hr" />
<div mat-dialog-content>
  <p class="p-content">
    {{ 'orders-front.fill_in_the_information_below' | translate }}
  </p>

  <form [formGroup]="form" class="flex">
    <div class="did-floating-label-content mr-10 mt-15">
      <input
        class="did-floating-input"
        formControlName="name"
        placeholder=" " />
      <label class="did-floating-label">{{ 'name' | translate }}*</label>
      <mat-error
        *ngIf="submitted && form.controls['name'].errors?.['required']"
        >{{ 'users-front.field_is_required' | translate }}</mat-error
      >
    </div>
    <div class="did-floating-label-content mr-10 mt-15">
      <input
        class="did-floating-input"
        formControlName="street"
        placeholder=" " />
      <label class="did-floating-label">{{ 'street' | translate }}*</label>
      <mat-error
        *ngIf="submitted && form.controls['street'].errors?.['required']"
        >{{ 'users-front.field_is_required' | translate }}</mat-error
      >
    </div>
    <div class="did-floating-label-content mr-10 mt-15">
      <input
        class="did-floating-input"
        formControlName="buildingNumber"
        placeholder=" " />
      <label class="did-floating-label"
        >{{ 'buildingNumber' | translate }}*</label
      >
      <mat-error
        *ngIf="
          submitted && form.controls['buildingNumber'].errors?.['required']
        "
        >{{ 'users-front.field_is_required' | translate }}</mat-error
      >
    </div>
    <div class="did-floating-label-content mr-10 mt-15">
      <input
        class="did-floating-input"
        formControlName="apartmentNumber"
        placeholder=" " />
      <label class="did-floating-label">{{
        'apartmentNumber' | translate
      }}</label>
    </div>
    <div class="did-floating-label-content mr-10 mt-15">
      <input
        class="did-floating-input"
        formControlName="postalCode"
        placeholder=" " />
      <label class="did-floating-label">{{ 'postalCode' | translate }}*</label>
      <mat-error
        *ngIf="submitted && form.controls['postalCode'].errors?.['required']"
        >{{ 'users-front.field_is_required' | translate }}</mat-error
      >
    </div>
    <div class="did-floating-label-content mr-10 mt-15">
      <input
        class="did-floating-input"
        formControlName="city"
        placeholder=" " />
      <label class="did-floating-label">{{ 'city' | translate }}*</label>
      <mat-error
        *ngIf="submitted && form.controls['city'].errors?.['required']"
        >{{ 'users-front.field_is_required' | translate }}</mat-error
      >
    </div>

    <mat-error *ngIf="error">{{ error | translate }}</mat-error>
  </form>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    class="secondary-btn"
    color="accent"
    [mat-dialog-close]="'cancel'">
    {{ 'orders-front.cancel' | translate }}
  </button>
  <button mat-button class="primary-btn" (click)="onSubmit()" color="primary">
    {{ 'orders-front.add' | translate }}
  </button>
</div>
