<div>
  <div class="tabs">
    <div
      class="tab"
      (click)="tab = 'details'"
      [class.active]="tab == 'details'">
      {{ 'orders-front.item.tabs.order_details' | translate }}
    </div>
    <div
      class="tab"
      (click)="tab = 'documents'"
      [class.active]="tab == 'documents'"
      *ngIf="Auth.isViewAllowed(['ROLE_ORDER_DOCUMENTS_VIEW', 'ROLE_ADMIN'])">
      {{ 'orders-front.item.tabs.documents' | translate }}
    </div>
    <div
      class="tab"
      (click)="tab = 'costs'"
      [class.active]="tab == 'costs'"
      *ngIf="Auth.isViewAllowed(['ROLE_BILLINGS_VIEW', 'ROLE_ADMIN'])">
      {{ 'orders-front.item.tabs.costs' | translate }}
    </div>
    <div
      class="tab"
      (click)="tab = 'packages'"
      [class.active]="tab == 'packages'"
      *ngIf="Auth.isViewAllowed(['ROLE_SHIPMENTS_VIEW', 'ROLE_ADMIN'])">
      {{ 'orders-front.item.tabs.packages' | translate }}
    </div>
    <div
      class="tab"
      (click)="tab = 'history'"
      [class.active]="tab == 'history'"
      *ngIf="Auth.isViewAllowed(['ROLE_HISTORY_DETAILS_VIEW', 'ROLE_ADMIN'])">
      {{ 'orders-front.item.tabs.history' | translate }}
    </div>
    <div class="actions">
      <button mat-button [matMenuTriggerFor]="menu" mat-stroked-button>
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <!-- <button mat-menu-item
          *ngIf="Auth.isViewAllowed(['ROLE_ORDER_DETAILS_EDIT', 'ROLE_ADMIN'])"><mat-icon>edit</mat-icon>Zmień
          serwis</button> -->

        <button mat-menu-item (click)="refresh()">
          <mat-icon>refresh</mat-icon>Odśwież
        </button>
      </mat-menu>
    </div>
  </div>
</div>
<app-archived-order-info [order]="order"></app-archived-order-info>
<app-archived-order-details
  *ngIf="tab === 'details'"
  [order]="order"
  [history]="history"></app-archived-order-details>
<app-archived-order-repair
  *ngIf="tab === 'history'"
  [history]="history"></app-archived-order-repair>
<app-archived-order-documents
  [data]="order"
  *ngIf="tab === 'documents'"></app-archived-order-documents>
<app-archived-order-packages
  [order]="order"
  *ngIf="tab === 'packages'"></app-archived-order-packages>
<app-archived-order-costs
  [order]="order"
  *ngIf="tab === 'costs'"></app-archived-order-costs>
