import { Component, Input } from '@angular/core';
import { faMessage, faUser, faWrench } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrl: './notification-item.component.scss',
})
export class NotificationItemComponent {

  faMessage = faMessage;

  faUser = faUser;

  faWrench = faWrench;

  @Input({required: true}) notificationType!: string;

  @Input({required: true}) title!: string;

  @Input({required: false}) description!: string;

  @Input({required: true}) time!: string;
}
