import { APP_INITIALIZER, NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SidenavComponent } from './shared/components/sidenav/sidenav.component';
import { MatIconModule } from '@angular/material/icon';
import {
  MatPaginatorModule,
  MatPaginatorIntl,
} from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { AppRoutingModule } from './app-routing.module';
import { ServicesComponent } from './pages/services/services.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { ArchivedOrdersComponent } from './pages/archived-orders/archived-orders.component';
import { MatListModule } from '@angular/material/list';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { OrderItemComponent } from './pages/orders/order-item/order-item.component';
import { ArchivedOrderItemComponent } from './pages/archived-orders/archived-order-item/archived-order-item.component';
import { MatButtonModule } from '@angular/material/button';
import { OrderRepairComponent } from './pages/orders/order-item/components/order-repair/order-repair.component';
import { ArchivedOrderRepairComponent } from './pages/archived-orders/archived-order-item/components/archived-order-repair/archived-order-repair.component';
import { OrderDocumentsComponent } from './pages/orders/order-item/components/order-documents/order-documents.component';
import { ArchivedOrderDocumentsComponent } from './pages/archived-orders/archived-order-item/components/archived-order-documents/archived-order-documents.component';
import { OrderDetailsComponent } from './pages/orders/order-item/components/order-details/order-details.component';
import { ArchivedOrderDetailsComponent } from './pages/archived-orders/archived-order-item/components/archived-order-details/archived-order-details.component';
import { OrderPackagesComponent } from './pages/orders/order-item/components/order-packages/order-packages.component';
import { ArchivedOrderPackagesComponent } from './pages/archived-orders/archived-order-item/components/archived-order-packages/archived-order-packages.component';
import { OrderCostsComponent } from './pages/orders/order-item/components/order-costs/order-costs.component';
import { ArchivedOrderCostsComponent } from './pages/archived-orders/archived-order-item/components/archived-order-costs/archived-order-costs.component';
import { ServiceItemComponent } from './pages/services/service-item/service-item.component';
import { CostsComponent } from './pages/costs/costs.component';
import { SettlementComponent } from './pages/settlement/settlement.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ShowDataComponent } from './shared/modals/show-data/show-data.component';
import { ShowPreAuthComponent } from './shared/modals/show-preauth/show-preauth.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AddNoteComponent } from './shared/modals/add-note/add-note.component';
import { AddCommentComponent } from './shared/modals/add-comment/add-comment.component';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AddAttachmentComponent } from './shared/modals/add-attachment/add-attachment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RequestInterceptor } from './shared/interceptors/request.interceptor';
import { SessionEndingComponent } from './shared/modals/session-ending/session-ending.component';
import { SessionLogoutComponent } from './shared/modals/session-logout/session-logout.component';
import { UsersComponent } from './pages/users/users.component';
import { MatSelectModule } from '@angular/material/select';
import { AddUserComponent } from './shared/modals/add-user/add-user.component';
import { EditUserComponent } from './shared/modals/edit-user/edit-user.component';
import { UserConfirmActionComponent } from './shared/modals/user-confirm-action/user-confirm-action.component';
import { ActivateUserConfirmComponent } from './shared/modals/activate-user-confirm/activate-user-confirm.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { LoadingInterceptor } from './shared/interceptors/spinner.interceptor';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EditMarginComponent } from './shared/modals/edit-margin/edit-margin.component';
import { ConfirmEditMarginComponent } from './shared/modals/confirm-edit-margin/confirm-edit-margin.component';
import { CheckMarginComponent } from './shared/modals/check-margin/check-margin.component';
import { ClickOutsideDirective } from './shared/directives/click-outside.directive';
import { SearchFilterPipe } from './shared/pipes/search.pipe';
import { DashIfEmptyPipe } from './shared/pipes/empty.pipe';
import { MatSortModule } from '@angular/material/sort';
import { OrderInfoComponent } from './pages/orders/order-info/order-info.component';
import { AddServiceInfoComponent } from './shared/modals/add-service/add-service-info/add-service-info.component';
import { ServiceMarginComponent } from './shared/modals/service-margin/service-margin.component';
import { SupportedHardwareComponent } from './shared/modals/supported-hardware/supported-hardware.component';
import { SupportedAreaComponent } from './shared/modals/supported-area/supported-area.component';
import { AddContactComponent } from './shared/modals/add-contact/add-contact.component';
import { SettlementItemComponent } from './pages/settlement/settlement-item/settlement-item.component';
import { CostsItemComponent } from './pages/costs/costs-item/costs-item.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ExamplePdfViewerComponent } from './example-pdf-viewer/example-pdf-viewer.component';
import { AddInvoiceComponent } from './shared/modals/add-invoice/add-invoice.component';
import { AddInvoicesComponent } from './shared/modals/add-invoices/add-invoices.component';
import { GenerateServiceCostsAndInsuranceComponent } from './shared/modals/generate-service-costs-and-insurance/generate-service-costs-and-insurance';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatTabsModule } from '@angular/material/tabs';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BreadcrumbComponent } from './shared/components/breadcrumb/breadcrumb.component';
import { getIntPaginatorIntl } from './shared/components/paginator/paginator-intl';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { ShowDocComponent } from './shared/modals/show-doc/show-doc-component';
import { MatMenuModule } from '@angular/material/menu';
import { SettlementInfoComponent } from './pages/costs/costs-item/components/settlement-info/settlement-info.component';
import { NgxTimelineModule } from '@frxjs/ngx-timeline';
import { TopNavbarComponent } from './shared/components/top-navbar/top-navbar.component';
import { GLOBAL_RX_STATE, GlobalState } from './shared/rx-state';
import { RxState } from '@rx-angular/state';
import { provideUserIdleConfig } from 'angular-user-idle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CommentsComponent } from './shared/modals/comments/comments';
import { OrderNotificationsComponent } from './shared/modals/notifications/notifications';
import { MatBadgeModule } from '@angular/material/badge';
import { PreAuthComponent } from './shared/modals/preAuth/preAuth';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { initializeKeycloak } from './shared/keycloak/keycloak-init.factory';
import { TimeAgoPipe } from './shared/pipes/timeAgo.pipe';
import { AdminPanelComponent } from './pages/admin-panel/admin-panel.component';
import { LogsComponent } from './pages/logs/logs.component';
import { LogsLocalComponent } from './pages/logs-local/logs-local.component';
import { DatePipe } from '@angular/common';
import { HttpErrorInterceptor } from './shared/interceptors/http_error.interceptor';
import { EditServiceInfoComponent } from './shared/modals/edit-service-info/edit-service-info.component';
import { ConfirmCancelOrderComponent } from './shared/modals/confirm-cancel-order/confirm-cancel-order.component';
import { FileSizePipe } from './shared/pipes/fileSize.pipe';
import { ConfirmEditMarginInOrderComponent } from './shared/modals/confirm-edit-margin-in-order/confirm-edit-margin-in-order.component';
import { ZipCodePipe } from './shared/pipes/zip-code.pipe';
import { IbanPipe } from './shared/pipes/iban.pipe';
import { ArchivedOrderInfoComponent } from './pages/archived-orders/archived-order-info/archived-order-info.component';
import { NgxMaterialIntlTelInputComponent } from 'ngx-material-intl-tel-input';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { EditMarginInOrderComponent } from './shared/modals/edit-margin-in-order/edit-margin-in-order.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { GenerateSettlementReportComponent } from './shared/modals/generate-settlement-report/generate-settlement-report';
import { GenerateCostsReportComponent } from './shared/modals/generate-costs-report/generate-costs-report';
import { IsEllipsisActiveDirective } from './shared/directives/is-ellipsis-active.dorective';
import { EditContactComponent } from './shared/modals/edit-contact/edit-contact';
import { SupportedBrandsComponent } from './shared/modals/supported-brands/supported-brands.component';
import { DeleteServiceConfirmComponent } from './shared/modals/delete-service-confirm/delete-service-confirm.component';
import { ShowDataDefaultComponent } from './shared/modals/show-data-default/show-data-default.component';
import { ChangeServiceComponent } from './shared/modals/change-service/change-service.component';
import { ConfirmArchiveOrderComponent } from './shared/modals/confirm-archive-order/confirm-archive-order.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { FinishOrderModalComponent } from './shared/modals/finish-order-modal/finish-order-modal.component';
import { AssignOrderModalComponent } from './shared/modals/assign-order-modal/assign-order-modal.component';
import { FixCostInvoiceComponent } from './shared/modals/fix-cost-invoice/fix-cost-invoice.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AddPreauthModalComponent } from './shared/modals/add-preauth-modal/add-preauth-modal.component';
import { EditPartsComponent } from './shared/modals/edit-parts/edit-parts.component';
import { EditCostsComponent } from './shared/modals/edit-costs/edit-costs.component';
import { RepairUnitComponent } from 'dashboard-libs/components/repair-unit/repair-unit.component';
import { AddRepairUnitComponent } from 'dashboard-libs/modals/add-repair-unit/add-repair-unit.component';
import { OrderProgressStepperComponent } from './pages/orders/order-info/order-progress-stepper/order-progress-stepper.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ProgressBarComponent } from './shared/components/progress-bar/progress-bar.component';
import { OrderInfoOverwiewComponent } from './pages/orders/order-info/order-info-overview/order-info-overview.component';
import { OrderInfoDetailsComponent } from './pages/orders/order-info/order-info-details/order-info-details.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OrderInfoCommentsComponent } from './pages/orders/order-info/order-info-comments/order-info-comments.component';
import { OrderInfoCommentComponent } from './pages/orders/order-info/order-info-comments/order-info-comment/order-info-comment.component';
import { OrderInfoTimelineComponent } from './pages/orders/order-info/order-info-timeline/order-info-timeline.component';
import { OrderInfoTimelineItemComponent } from './pages/orders/order-info/order-info-timeline/order-info-timeline-item/order-info-timeline-item.component';
import { CustomDatePipe } from './shared/pipes/customDate.pipe';
import { OrderInfoStatusComponent } from './pages/orders/order-info/order-info-status/order-info-status.component';
import { NotificationsWindowComponent } from './shared/components/notifications-window/notifications-window.component';
import { MaskTextPipe } from './shared/pipes/MaskText.pipe';
import { NoContentComponent } from './shared/components/no-content/no-content.component';
import { NotificationItemComponent } from './shared/modals/notifications/notification-item/notification-item.component';
import { CapitalizePipe } from './shared/pipes/capitalize.pipe';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    SupportedBrandsComponent,
    GenerateServiceCostsAndInsuranceComponent,
    ShowDataDefaultComponent,
    IsEllipsisActiveDirective,
    ChangeServiceComponent,
    EditContactComponent,
    AddRepairUnitComponent,
    BreadcrumbComponent,
    AddCommentComponent,
    RepairUnitComponent,
    ArchivedOrderRepairComponent,
    AssignOrderModalComponent,
    AppComponent,
    LogsComponent,
    ArchivedOrdersComponent,
    SidenavComponent,
    ServicesComponent,
    ArchivedOrderDocumentsComponent,
    OrdersComponent,
    OrderItemComponent,
    OrderRepairComponent,
    ArchivedOrderItemComponent,
    OrderDocumentsComponent,
    OrderDetailsComponent,
    OrderPackagesComponent,
    OrderCostsComponent,
    ServiceItemComponent,
    CostsComponent,
    ArchivedOrderCostsComponent,
    SettlementComponent,
    ArchivedOrderDetailsComponent,
    SettingsComponent,
    ArchivedOrderPackagesComponent,
    ArchivedOrderInfoComponent,
    ShowDataComponent,
    ShowPreAuthComponent,
    AddNoteComponent,
    AddAttachmentComponent,
    SessionEndingComponent,
    SessionLogoutComponent,
    UsersComponent,
    AddUserComponent,
    EditUserComponent,
    UserConfirmActionComponent,
    ActivateUserConfirmComponent,
    EditMarginComponent,
    ConfirmEditMarginComponent,
    CheckMarginComponent,
    ClickOutsideDirective,
    SearchFilterPipe,
    DashIfEmptyPipe,
    MaskTextPipe,
    CustomDatePipe,
    FileSizePipe,
    ZipCodePipe,
    IbanPipe,
    OrderInfoComponent,
    SettlementInfoComponent,
    AddServiceInfoComponent,
    ServiceMarginComponent,
    SupportedHardwareComponent,
    SupportedAreaComponent,
    AddContactComponent,
    SettlementItemComponent,
    CostsItemComponent,
    ExamplePdfViewerComponent,
    AddInvoiceComponent,
    AddInvoicesComponent,
    PreAuthComponent,
    ShowDocComponent,
    TopNavbarComponent,
    CommentsComponent,
    OrderNotificationsComponent,
    TimeAgoPipe,
    CapitalizePipe,
    MyProfileComponent,
    AdminPanelComponent,
    EditMarginInOrderComponent,
    LogsLocalComponent,
    EditServiceInfoComponent,
    ConfirmCancelOrderComponent,
    ConfirmEditMarginInOrderComponent,
    ReportsComponent,
    GenerateSettlementReportComponent,
    GenerateCostsReportComponent,
    DeleteServiceConfirmComponent,
    ConfirmArchiveOrderComponent,
    FinishOrderModalComponent,
    FixCostInvoiceComponent,
    AddPreauthModalComponent,
    EditPartsComponent,
    EditCostsComponent,
    OrderInfoOverwiewComponent,
    OrderInfoDetailsComponent,
    OrderProgressStepperComponent,
    ProgressBarComponent,
    OrderInfoCommentsComponent,
    OrderInfoCommentComponent,
    OrderInfoTimelineComponent,
    OrderInfoStatusComponent,
    OrderInfoTimelineItemComponent,
    NotificationsWindowComponent,
    NoContentComponent,
    NotificationItemComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    InfiniteScrollModule,
    NgxDocViewerModule,
    ContextMenuModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonToggleModule,
    MatStepperModule,
    MatTooltipModule,
    RouterModule,
    BrowserModule,
    KeycloakAngularModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatIconModule,
    MatBadgeModule,
    MatPaginatorModule,
    NgxMaterialIntlTelInputComponent,
    MatTableModule,
    MatExpansionModule,
    AppRoutingModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatSortModule,
    FormsModule,
    ScrollingModule,
    NgxTimelineModule,
    MatTabsModule,
    NgxExtendedPdfViewerModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    {
      provide: LOCALE_ID,
      useValue: 'pl',
    },
    DatePipe,
    { provide: MatPaginatorIntl, useValue: getIntPaginatorIntl() },
    { provide: GLOBAL_RX_STATE, useFactory: () => new RxState<GlobalState>() },
    provideUserIdleConfig({ idle: 1200, timeout: 120, ping: 420 }),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
