<ng-container
  *ngIf="Auth.isViewAllowed(['ROLE_BILLINGS_COSTS_VIEW', 'ROLE_ADMIN'])">
  <div class="tabs">
    <div
      class="tab"
      (click)="changeGroup(item)"
      [class.active]="tab == item.name"
      *ngFor="let item of statusGroup">
      <p>
        <b>{{ item.name }}</b>
      </p>
      <p class="center blue">
        {{ statesCountGrouped[item.name] || 0 }}
      </p>
    </div>
  </div>
  <div class="actions">
    <button mat-button [matMenuTriggerFor]="menu" mat-stroked-button>
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <!-- <button mat-menu-item
        *ngIf="Auth.isViewAllowed(['ROLE_ORDER_DETAILS_EDIT', 'ROLE_ADMIN'])"><mat-icon>edit</mat-icon>Zmień
        serwis</button> -->
      <button mat-menu-item (click)="downloadSummary()">
        <mat-icon>download</mat-icon> {{ 'Download summary' | translate }}
      </button>
      <button mat-menu-item (click)="settle()">
        <mat-icon>file_open</mat-icon> {{ 'Rozlicz' | translate }}
      </button>
      <button
        mat-menu-item
        (click)="fixInvoice()"
        *ngIf="Auth.isViewAllowed(['ROLE_ADMIN'])">
        <mat-icon>note_add</mat-icon>
        {{ 'Zaktualizuj numer faktury dla okresu' | translate }}
      </button>
    </mat-menu>
  </div>
  <mat-card>
    <div
      class="filters"
      *ngIf="Auth.isViewAllowed(['ROLE_ORDERS_SEARCH', 'ROLE_ADMIN'])">
      <div
        class="buttons right"
        *ngIf="Auth.isViewAllowed(['ROLE_ORDERS_SEARCH', 'ROLE_ADMIN'])">
        <button
          class="filter-button"
          mat-raised-button
          color="primary"
          (click)="sendFilters()"
          color="primary">
          {{ 'search' | translate }}
        </button>
        <button
          class="filter-button"
          mat-stroked-button
          (click)="clearFilters()"
          color="primary">
          {{ 'clear' | translate }}
        </button>

        <button
          class="filter-button"
          mat-stroked-button
          (click)="showFilters = !showFilters"
          color="primary">
          <p *ngIf="!showFilters">
            <mat-icon>filter_alt</mat-icon>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </p>
          <p *ngIf="showFilters">
            <mat-icon>filter_alt</mat-icon>
            <mat-icon>keyboard_arrow_up</mat-icon>
          </p>
        </button>
      </div>
      <div class="filters-inputs" [ngClass]="{ 'showFilters': showFilters }">
        <mat-form-field class="filter-field" appearance="outline">
          <mat-label class="form-label">{{
            'faultNumber' | translate
          }}</mat-label>
          <input
            matInput
            class="form-field"
            [formControl]="faultNumber"
            [(ngModel)]="faultNumberValue" />
          @if (faultNumberValue) {
            <button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="faultNumberValue = ''">
              <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>
        <mat-form-field class="filter-field" appearance="outline">
          <mat-label class="form-label">{{
            'orderCreatedAt' | translate
          }}</mat-label>
          <mat-date-range-input [rangePicker]="picker2">
            <input
              matStartDate
              [formControl]="orderCreatedAtFrom"
              [(ngModel)]="orderCreatedAtFromValue"
              placeholder="{{ 'Start_date' | translate }}" />
            <input
              matEndDate
              [(ngModel)]="orderCreatedAtToValue"
              [formControl]="orderCreatedAtTo"
              placeholder="{{ 'End_date' | translate }}" />
          </mat-date-range-input>

          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker2"></mat-datepicker-toggle>
          <mat-date-range-picker #picker2></mat-date-range-picker>
          @if (orderCreatedAtFromValue || orderCreatedAtToValue) {
            <button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="
                orderCreatedAtFromValue = ''; orderCreatedAtToValue = ''
              ">
              <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>
        <mat-form-field class="filter-field" appearance="outline">
          <mat-label class="form-label">{{
            'orderFinishDate' | translate
          }}</mat-label>
          <mat-date-range-input [rangePicker]="picker1">
            <input
              matStartDate
              [formControl]="orderFinishDateFrom"
              [(ngModel)]="orderFinishDateFromValue"
              placeholder="{{ 'Start_date' | translate }}" />
            <input
              matEndDate
              [formControl]="orderFinishDateTo"
              [(ngModel)]="orderFinishDateToValue"
              placeholder="{{ 'End_date' | translate }}" />
          </mat-date-range-input>

          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker1"></mat-datepicker-toggle>
          <mat-date-range-picker #picker1></mat-date-range-picker>
          @if (orderFinishDateFromValue || orderFinishDateToValue) {
            <button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="
                orderFinishDateFromValue = ''; orderFinishDateToValue = ''
              ">
              <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>

        <mat-form-field class="filter-field" appearance="outline">
          <mat-label class="form-label">{{
            'invoicePeriod' | translate
          }}</mat-label>
          <mat-select
            [formControl]="invoicePeriod"
            [(ngModel)]="invoicePeriodValue"
            (keydown.enter)="sendFilters()">
            <mat-option
              *ngFor="let period of billingPeriods"
              [value]="period.period"
              >{{ period.period }}</mat-option
            >
          </mat-select>
          @if (invoicePeriodValue) {
            <button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="invoicePeriodValue = ''">
              <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>

        <mat-form-field class="filter-field" appearance="outline">
          <mat-label class="form-label">{{ 'workshop' | translate }}</mat-label>
          <mat-select
            [(ngModel)]="orderWorkshopNameValue"
            [formControl]="orderWorkshopName"
            (keydown.enter)="sendFilters()">
            <mat-option
              *ngFor="let workshop of workshops"
              [value]="workshop.name">
              {{ workshop.name }}</mat-option
            >
          </mat-select>
          @if (orderWorkshopNameValue) {
            <button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="orderWorkshopNameValue = ''">
              <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>
      </div>
    </div>

    <div class="table">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        (matSortChange)="sortData($event)">
        <ng-container matColumnDef="Lp">
          <th mat-header-cell *matHeaderCellDef>Lp.</th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ itemsPerPage * pageIndex + i + 1 }}
          </td>
        </ng-container>
        <ng-container matColumnDef="faultNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'settlements-front.table.order_number' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <p
              [ngClass]="element?.alertType"
              [matTooltip]="
                element?.alertType == 'warning'
                  ? 'Problem ze zeleceniem'
                  : '' || element?.alertType == 'success'
                    ? 'Zlecenie udało się '
                    : '' || element?.alertType == 'danger'
                      ? 'Zlecenie ma problem '
                      : ''
              ">
              {{ element.faultNumber | dashIfEmpty }}
            </p>
          </td>
        </ng-container>

        <ng-container matColumnDef="orderFinishDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'settlements-front.table.order_end_date' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              element.orderFinishDate | date: 'YYYY-MM-dd | HH:mm' | dashIfEmpty
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="customer">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'settlements-front.table.client' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.customer | dashIfEmpty }}
          </td>
        </ng-container>

        <ng-container matColumnDef="orderWorkshopName">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'settlements-front.table.service' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.orderWorkshopName | dashIfEmpty }}
          </td>
        </ng-container>

        <ng-container matColumnDef="invoicePeriod">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'settlements-front.table.invoicePeriod' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.invoicePeriod | dashIfEmpty }}
          </td>
        </ng-container>

        <ng-container matColumnDef="orderState">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'orderState' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.orderState | dashIfEmpty | translate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="orderCreatedAt">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'settlements-front.table.order_date' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <p [ngClass]="element?.alertType">
              {{
                element.orderCreatedAt
                  | date: 'YYYY-MM-dd | HH:mm'
                  | dashIfEmpty
              }}
            </p>
            <p></p>
          </td>
        </ng-container>

        <ng-container matColumnDef="totalGross">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'settlements-front.table.cost' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              element.totalGross
                | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
                | dashIfEmpty
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="revenueInvoiceNumber">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'settlements-front.table.FV_nr' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.invoiceNumber | dashIfEmpty }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'settlements-front.table.actions' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon>more_vert</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          class="hovered"
          mat-row
          [contextMenu]="oneContextMenu"
          [contextMenuValue]="row"
          (click)="goToDetails(row.id, $event)"
          *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <div class="empty-table-view" *ngIf="dataSource?.data?.length === 0">
        <p>{{ 'no_data' | translate }}</p>
      </div>
      <mat-paginator
        [pageSizeOptions]="[10, 20, 30, 50]"
        [pageSize]="itemsPerPage"
        [pageIndex]="pageIndex"
        (page)="onPaginateChange($event)"
        aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>
  </mat-card>
</ng-container>

<context-menu #oneContextMenu menuClass="custom-style">
  <ng-template contextMenuItem let-value (execute)="openInNewTabCost($event)">
    Otwórz w nowym oknie</ng-template
  >
  <ng-template contextMenuItem [subMenu]="specialPast"
    >Kopiuj do schowka ...</ng-template
  >
  <ng-template
    contextMenuItem
    (execute)="openInNewTabSettlement($event)"
    let-value>
    Przejdz do rozliczenia tego zlecenia</ng-template
  >
  <ng-template contextMenuItem (execute)="openInNewTabOrder($event)" let-value>
    Przejdz do tego zlecenia</ng-template
  >
  <ng-template contextMenuItem (execute)="openLogsOfOrder($event)" let-value
    >Przejdz do logów zlecenia</ng-template
  >
</context-menu>

<context-menu #specialPast>
  <ng-template contextMenuItem (execute)="copy($event, 'faultNumber')">
    Nr zlecenia</ng-template
  >
  <ng-template contextMenuItem (execute)="copy($event, 'revenueInvoiceNumber')">
    Nr faktury</ng-template
  >
</context-menu>
