<ng-container>
  <mat-card
    *ngIf="Auth.isViewAllowed(['ROLE_SETTINGS_USERS_VIEW', 'ROLE_ADMIN'])">
    <div class="card">
      <div class="card-title text-right">
        <div class="search-input">
          <div class="did-floating-label-content search">
            <input
              class="did-floating-input"
              (keyup)="applyFilter($event)"
              type="text"
              placeholder=" "
              #input />
            <label class="did-floating-label">{{
              'users-front.title' | translate
            }}</label>
            <mat-icon>search</mat-icon>
          </div>
        </div>
        <button
          mat-stroked-button
          (click)="addUser()"
          *ngIf="
            Auth.isViewAllowed(['ROLE_SETTINGS_USERS_MANAGE', 'ROLE_ADMIN'])
          ">
          {{ 'users-front.add_user' | translate }}
        </button>
      </div>
      <hr class="custom-hr" />
      <div class="table">
        <table
          mat-table
          [dataSource]="dataSource"
          matSortActive="enabled"
          matSortDirection="desc"
          matSort>
          <!-- Position Column -->
          <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'users-front.table.name' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              (click)="show({ value: element })">
              {{ element.firstName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'users-front.table.surname' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              (click)="show({ value: element })">
              {{ element.lastName }}
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'users-front.table.email_address' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              (click)="show({ value: element })">
              {{ element.email }}
            </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="createdTimestamp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'users-front.table.created_date' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              (click)="show({ value: element })">
              {{ element.createdTimestamp | date: 'YYYY-MM-dd HH:mm' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="enabled">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'users-front.table.state' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              (click)="show({ value: element })">
              {{ element.enabled ? 'Aktywny' : 'Nieaktywny' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="emailVerified">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'users-front.table.verified' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              (click)="show({ value: element })">
              {{ element.emailVerified ? 'Tak' : 'Nie' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="notBefore">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'users-front.table.last_login_date' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              (click)="show({ value: element })">
              {{
                element?.lastLoginDate
                  ? (element?.lastLoginDate | date: 'YYYY-MM-dd HH:mm:ss')
                  : '-'
              }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            class="hovered"
            mat-row
            [contextMenu]="oneContextMenu"
            [contextMenuValue]="row"
            *matRowDef="let row; columns: displayedColumns"
            [class.inactive]="!row.enabled"></tr>
        </table>
        <div class="empty-table-view" *ngIf="dataSource?.data?.length === 0">
          <p>{{ 'no_data' | translate }}</p>
        </div>
        <mat-paginator
          [pageSize]="30"
          [pageSizeOptions]="[10, 20, 30, 50]"
          aria-label="Ilość rekordów"></mat-paginator>
      </div>
    </div>
  </mat-card>
</ng-container>

<context-menu #oneContextMenu menuClass="custom-style">
  <ng-template contextMenuItem let-value (execute)="show($event)">
    Sprawdż użytkownika</ng-template
  >

  <ng-template
    contextMenuItem
    let-value
    (execute)="edit($event)"
    *ngIf="Auth.isViewAllowed(['ROLE_SETTINGS_USERS_MANAGE', 'ROLE_ADMIN'])">
    Edytuj użytkownika</ng-template
  >

  <ng-template
    contextMenuItem
    let-value
    (execute)="activateDeactivateUser($event)">
    <p
      class="button-p"
      *ngIf="
        value.enabled &&
        Auth.isViewAllowed(['ROLE_SETTINGS_USERS_MANAGE', 'ROLE_ADMIN'])
      ">
      Dezaktywuj użytkownika
    </p>
    <p
      class="button-p"
      *ngIf="
        !value.enabled &&
        Auth.isViewAllowed(['ROLE_SETTINGS_USERS_MANAGE', 'ROLE_ADMIN'])
      ">
      Aktywuj użytkownika
    </p>
  </ng-template>
</context-menu>
