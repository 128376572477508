import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private defaultTitle = 'DRS';

  constructor(private titleService: Title, private router: Router) {}

  setTitle() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        const root = this.router.routerState.snapshot.root;
        const routeTitle = this.getRouteTitle(root);
        this.titleService.setTitle(`${this.defaultTitle} | ${routeTitle}` || `${this.defaultTitle} | Services`);
      });
  }

  private getRouteTitle(route: ActivatedRouteSnapshot): string | null {
    if (route.data && route.data['title']) {
      return route.data['title'];
    }

    for (const child of route.children) {
      const childTitle = this.getRouteTitle(child);
      if (childTitle) {
        return childTitle;
      }
    }

    return null;
  }
}
