import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NotificationComment } from '../../../../../dashboard-libs/models/notificationComment.model';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.html',
  styleUrls: ['./comments.scss'],
})

export class CommentsComponent implements OnInit {
  commentsFilterCategory: 'ALL' | 'UNREAD' = "UNREAD";
  
  public selector: string = '.container-comments';

  public comments: NotificationComment[] = [];

  public isLoading = true;

  constructor(private notificationService: NotificationService) {}

  @Output() dismissOpenModal = new EventEmitter<void>();

  onDismissOpenModal() : void {
    this.dismissOpenModal.emit();
  }

  ngOnInit(): void {
    this.notificationService.commentsCategory$.subscribe(commentsCategory => {
      this.commentsFilterCategory = commentsCategory;
      this.loadComments();
    });
  }

  onScrollDown(): void {
    this.loadNextComments();
  }

  public loadComments(): void {
    this.isLoading = true;
    this.notificationService.fetchCommentNotifications(this.commentsFilterCategory).subscribe({
      next: (filteredComments : any) => {
        this.comments = filteredComments;
        this.notificationService.updatePagination();
        this.isLoading = false;
      },
      error: (err : any) => {
        console.error('Error loading comments:', err);
        this.isLoading = false;
      },
    });
  }

  public isMoreCommentsLoading: boolean = false;

  private loadNextComments(): void {
    this.isMoreCommentsLoading = true;
    this.notificationService.fetchNextComments(this.commentsFilterCategory).subscribe({
      next: (nextComments : any) => {
        this.comments = [...this.comments, ...nextComments];
        this.notificationService.updatePagination();
        this.isMoreCommentsLoading = false;
      },
      error: (err : any) => {
        console.error('Error loading next comments:', err);
        this.isLoading = false;
      },
    });
  }
}