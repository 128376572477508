<main>
  <div class="side-nav-container">
    <app-sidenav/>
  </div>
  <div class="top-nav-container">
    <app-top-navbar/>
  </div>
  <div class="main-page-content-container">
    @if(this.spinnerService.getLoading()){
      <div class="spinner-container">
        <mat-spinner class="spinner"/>
      </div>
    }
    <router-outlet/>
  </div>
</main>