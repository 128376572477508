import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthGuard } from 'src/app/shared/guards/access-allowed.guard';
import { OrdersService } from '../../../services/orders.service';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { EditMarginInOrderComponent } from 'src/app/shared/modals/edit-margin-in-order/edit-margin-in-order.component';

@Component({
  selector: 'app-order-costs',
  templateUrl: './order-costs.component.html',
  styleUrls: ['./order-costs.component.scss'],
})
export class OrderCostsComponent implements OnInit {
  dataSource!: MatTableDataSource<any>;
  @ViewChild(MatSort) sort!: MatSort;
  displayedColumns: string[] = [
    'Lp',
    'date',
    'author',
    'transport_cost',
    'service_cost',
    'consultation_cost',
    'parts_cost',
    'own_share_percent',
    'own_share',

    'total_netto',
    'total_brutto',
  ];
  details: any = false;
  @Input() order: any;

  constructor(
    public Auth: AuthGuard,
    private orderService: OrdersService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.order.costsHistory);
    this.dataSource.sort = this.sort;

    console.log(this.order.costsHistory);

    if (this.order.costs) {
      this.order.costs.courier_sum =
        (this.order?.costs?.courier || 0) + (this.order?.costs?.services || 0);

      this.order.costs.consultation_sum =
        (this.order?.costs?.consultation || 0) +
        (this.order?.costs?.replacement || 0);
    }
    this.dataSource.data.sort(this.sortByResDate);
    this.getMargin();
  }

  sortByResDate(r1: any, r2: any) {
    let d1 = moment(r1.resdate, 'DD.MM.YYYY HH:mm:ss');
    let d2 = moment(r2.resdate, 'DD.MM.YYYY HH:mm:ss');

    if (d1 > d2) {
      return 1;
    } else if (d1 === d2) {
      return 0;
    } else return -1;
  }

  public marginId!: string;
  public margin: any;
  public marginLoaded = false;

  getMargin() : void {
    this.marginId = this.marginId = this.order.margin.split('/')[5];
    if (this.Auth.isViewAllowed(['ROLE_ORDER_MARGIN_VIEW', 'ROLE_ADMIN'])) {
      this.orderService.getOrderMargin(this.marginId).subscribe(res => {
        this.margin = res;
        this.marginLoaded = true;
      });
    }
  }

  openMargin(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    console.log(this.marginLoaded);
    
    if (this.marginLoaded) {
      const dialogRef = this.dialog.open(EditMarginInOrderComponent, {
        data: { margin: this.margin, fromOrder: true, state: this.order.state },
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe(result => {
        this.orderService.getOrderMargin(this.marginId).subscribe(res => {
          this.margin = res;
        });
        if (result === 'show') {
          /* this.openMargin(); */
        }
      });
    }
  }
}
