<app-order-info-details title="Zdarzenia" [isOpen]="true"> 
    <div class="timeline-container">
        @if(timelineEvents && timelineEvents.length){
            @for (activityLog of timelineEvents; track activityLog.eventData.uid; let last = $last) {
                <app-order-info-timeline-item
                    [title]="activityLog.eventData.title"
                    [date]="activityLog.eventDate"
                    [detailedInfo]="activityLog.eventData.detailedInfo"
                    [isLastItem]="last"
                    [alertType]="activityLog.eventData.alertType"
                />
            }
        }
        @else{
            <app-no-content
              title="Brak historii zdarzeń"
            />
          }
    </div>
</app-order-info-details>