import { Component, Input } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthGuard } from 'dashboard-libs/guards/access-allowed.guard';

import { AddRepairUnitComponent } from 'dashboard-libs/modals/add-repair-unit/add-repair-unit.component';
import { OrdersService } from 'src/app/pages/orders/services/orders.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-repair-unit',
  templateUrl: './repair-unit.component.html',
  styleUrls: ['./repair-unit.component.scss'],
})
export class RepairUnitComponent {
  @Input() workshopId: any;
  dataSource: any;
  displayedColumns: any = [
    'name',
    'street',
    'buildingNumber',
    'apartmentNumber',
    'postalCode',
    'city',
  ];
  user: any;
  workshop: any;
  showButtons: boolean | undefined;
  workshopUserId: any;

  constructor(
    public orderService: OrdersService,
    public userService: UserService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public Auth: AuthGuard
  ) {
    this.userService.getUserInfo().subscribe((res: any) => {
      this.user = res;
      console.log(this.user);
      this.workshop = this.user.workshop.split('/')[3];
      this.workshop = this.user.workshop.split('/').pop();
      this.workshopId = this.route.snapshot.params['id'];
      console.log(this.workshopId);
      console.log(this.workshop);
      if (this.workshop == this.workshopId) {
        this.showButtons = true;
      } else {
        this.showButtons = false;
      }
      console.log(this.showButtons);
      this.orderService
        .getRepairUnitsByWorkshop(this.workshopId)
        .subscribe((res: any) => {
          this.dataSource = res['hydra:member'];
          console.log(res);
        });
    });
    // '018f7703-a65a-77c1-b9fa-37b800dc7dc5'
    // const data = {
    //   'name': 'test',
    //   'street': 'test',
    //   'buildingNumber': 'test',
    //   'apartmentNumber': 'test',
    //   'postalCode': 'test',
    //   'city': 'test',
    // };
    // this.orderService.addRepairUnit(data).subscribe(res => {
    //   console.log(res);
    // });
  }

  ngOnInit(): void {}

  reloadData() {
    this.orderService
      .getRepairUnitsByWorkshop(this.workshopId)
      .subscribe((res: any) => {
        this.dataSource = res['hydra:member'];
        console.log(res);
      });
  }
  addRepairUnit() {
    const dialogRef = this.dialog.open(AddRepairUnitComponent, {
      disableClose: true,
      width: '700px',
      data: {
        workshopId: this.workshopId,
        admin: false,
      },
    });
    dialogRef.afterClosed().subscribe(submit => {
      if (submit.data) {
        this.orderService.addRepairUnit(submit.data).subscribe(res => {
          console.log(res);
          this.reloadData();
        });
      }
    });
  }

  addRepairUnitAsAdmin() {
    const dialogRef = this.dialog.open(AddRepairUnitComponent, {
      width: '700px',
      disableClose: true,
      data: {
        workshopId: this.workshopId,
        admin: true,
      },
    });
    dialogRef.afterClosed().subscribe(submit => {
      if (submit.data) {
        submit.data['workshopId'] = this.workshopId;
        this.orderService.addRepairUnit(submit.data).subscribe(res => {
          console.log(res);
          this.reloadData();
        });
      }
    });
  }
}
