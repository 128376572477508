import { Component, Input } from '@angular/core';
import { faAngleDown, faAngleUp, faCoffee } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-order-info-details',
  templateUrl: './order-info-details.component.html',
  styleUrl: './order-info-details.component.scss',
})
export class OrderInfoDetailsComponent {
  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;

  @Input() isOpen : boolean = false;
  @Input({required: true}) title: string = "";

  toggleOrderDetailsOpen() : void {
    this.isOpen = !this.isOpen;
  }
}
