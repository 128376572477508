import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import {
  animate,
  query,
  sequence,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { KeycloakService } from 'keycloak-angular';
import { AuthGuard } from '../../guards/access-allowed.guard';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/app/environments/environment';

export interface NavigationRoute {
  type: 'link' | 'expand';
  expandProperty?: string;
  sectionEnd?: boolean;
  route?: string;
  title: string;
  icon: string;
  canActivate: boolean;
  childRoutes?: NavigationRoute[]
}

export const DropDownAnimation = trigger('dropDownMenu', [
  transition(':enter', [
    style({ height: 0, overflow: 'hidden' }),
    query('.menu-item', [
      style({ opacity: 0, transform: 'translateY(-50px)' }),
    ]),
    sequence([
      animate('200ms', style({ height: '*' })),
      query('.menu-item', [
        stagger(-50, [
          animate('400ms ease', style({ opacity: 1, transform: 'none' })),
        ]),
      ]),
    ]),
  ]),

  transition(':leave', [
    style({ height: '*', overflow: 'hidden' }),
    query('.menu-item', [style({ opacity: 1, transform: 'none' })]),
    sequence([
      query('.menu-item', [
        stagger(50, [
          animate(
            '400ms ease',
            style({ opacity: 0, transform: 'translateY(-50px)' })
          ),
        ]),
      ]),
      animate('200ms', style({ height: 0 })),
    ]),
  ]),
]);

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit{

  [key: string]: any;
  
  readonly isUserDetailsVisible : boolean = true;

  readonly appLogo : string = 'assets/drs-logo.png';

  isCostTabExpanded: boolean = false;
  
  isSettingsTabExpanded: boolean = false;

  language: string = environment.language;

  user: any;

  constructor(
    public Auth: AuthGuard, 
    private userService: UserService,
    private keycloak: KeycloakService
  ) { }

  appRoutes : NavigationRoute[] = [
    {
      type: 'link',
      title: 'navbar.orders',
      route: '/orders',
      icon: 'dashboard',
      canActivate: true
    },
    {
      type: 'expand',
      expandProperty: 'isCostTabExpanded',
      title: 'navbar.settlements',
      icon: 'assignment',
      canActivate: this.Auth.isViewAllowed(['ROLE_BILLINGS_COSTS_VIEW', 'ROLE_BILLINGS_REVENUE_VIEW', 'ROLE_ADMIN']),
      childRoutes: [
        {
          type: 'link',
          title: 'Koszty',
          route: '/costs',
          canActivate: this.Auth.isViewAllowed(['ROLE_BILLINGS_COSTS_VIEW', 'ROLE_ADMIN']),
          icon: 'payment'
        },
        {
          type: 'link',
          title: 'Przychody',
          route: '/settlements',
          canActivate: this.Auth.isViewAllowed(['ROLE_BILLINGS_REVENUE_VIEW', 'ROLE_ADMIN']),
          icon: 'receipt'
        }
      ]
    },
    {
      type: 'link',
      title: 'navbar.services',
      route: '/workshop',
      icon: 'build',
      sectionEnd: true,
      canActivate: this.Auth.isViewAllowed(['ROLE_WORKSHOP_VIEW', 'ROLE_ADMIN'])
    },
    {
      type: 'link',
      title: 'navbar.archived-orders',
      route: '/archived-orders',
      icon: 'list_alt',
      canActivate: this.Auth.isViewAllowed(['ROLE_REPORTS', 'ROLE_ADMIN'])
    },
    {
      type: 'link',
      title: 'navbar.reports',
      route: '/reports',
      sectionEnd: true,
      icon: 'bar_chart',
      canActivate: this.Auth.isViewAllowed(['ROLE_REPORTS', 'ROLE_ADMIN'])
    },
    {
      type: 'expand',
      expandProperty: 'isSettingsTabExpanded',
      title: 'navbar.settings',
      icon: 'settings',
      canActivate: this.Auth.isViewAllowed(['ROLE_LOGS', 'ROLE_CRONS', 'ROLE_SYSTEM_MARGIN_SETTINGS', 'ROLE_SETTINGS_USERS_VIEW', 'ROLE_ADMIN']),
      childRoutes: [
        {
          type: 'link',
          title: 'navbar.users',
          route: '/users',
          canActivate: this.Auth.isViewAllowed(['ROLE_SETTINGS_USERS_VIEW', 'ROLE_ADMIN']),
          icon: 'assignment_ind'
        },
        {
          type: 'link',
          title: 'navbar.margin_system',
          route: '/settings',
          canActivate: this.Auth.isViewAllowed(['ROLE_SYSTEM_MARGIN_SETTINGS', 'ROLE_ADMIN']),
          icon: 'extension icon'
        },
        {
          type: 'link',
          title: 'navbar.crons',
          route: '/admin-panel',
          canActivate: this.Auth.isViewAllowed(['ROLE_CRONS', 'ROLE_ADMIN']),
          icon: 'brightness_auto'
        },
        {
          type: 'link',
          title: 'navbar.logs',
          route: '/logs',
          canActivate: this.Auth.isViewAllowed(['ROLE_LOGS', 'ROLE_ADMIN']),
          icon: 'memory'
        },
        {
          type: 'link',
          title: 'navbar.logs-local',
          route: '/logs-local',
          canActivate: this.Auth.isViewAllowed(['ROLE_LOGS_LOCAL', 'ROLE_ADMIN']),
          icon: 'disc_full'
        }
      ]
    },
  ];

  logout(): void {
    localStorage.clear();
    this.keycloak.logout();
  }

  toggleProperty(propertyName: string): void {
    this[propertyName] = !this[propertyName];
  }

  ngOnInit(): void {
      this.fetchUserData();
  }

  fetchUserData() : void {
    this.userService.getUserInfo().subscribe({
      next : (userData) => {
        this.user = userData;
        this.userService.setUser(userData);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
}
