<ng-container>
  <mat-card class="card-container">
    <mat-card class="example-card">
      <mat-card-header>
        <mat-card-title>Zestawienia rozliczonych przychodów</mat-card-title>
      </mat-card-header>
      <!-- <mat-card-content>
        <p>Lista rozliczonych zleceń z danego przedziału czasowego</p>
      </mat-card-content> -->
      <mat-card-actions>
        <button mat-button (click)="generateSettlementSummary()">
          Generuj dokument
        </button>
      </mat-card-actions>
    </mat-card>
    <mat-card class="example-card">
      <mat-card-header>
        <mat-card-title>Zestawienia rozliczonych kosztów</mat-card-title>
      </mat-card-header>
      <!-- <mat-card-content>
        <p>Lista kosztów zleceń z danego przedziału czasowego</p>
      </mat-card-content> -->
      <mat-card-actions>
        <button mat-button (click)="generateCostsSummary()">
          Generuj dokument
        </button>
      </mat-card-actions>
    </mat-card>
    <mat-card class="example-card">
      <mat-card-header>
        <mat-card-title
          >Raport kosztów serwisu i kwota na polisie</mat-card-title
        >
      </mat-card-header>
      <!-- <mat-card-content>
        <p>Lista kosztów zleceń z danego przedziału czasowego</p>
      </mat-card-content> -->
      <mat-card-actions>
        <button mat-button (click)="generateServiceCostsAndInsurance()">
          Generuj dokument
        </button>
      </mat-card-actions>
    </mat-card>
  </mat-card>
</ng-container>
