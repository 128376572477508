import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';

    const eventDate = new Date(value);
    const today = new Date();

    const isToday =
      eventDate.getDate() === today.getDate() &&
      eventDate.getMonth() === today.getMonth() &&
      eventDate.getFullYear() === today.getFullYear();

    if (isToday) {
      return `Dzisiaj | ${eventDate.toLocaleTimeString('pl-PL')}`;
    } else {
      return `${eventDate.toISOString().split('T')[0]} | ${eventDate.toLocaleTimeString('pl-PL')}`;
    }
  }
}
